import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TaxModel } from './model/tax.model';
import { tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaxService {
  private baseUrl = `${environment.apiUrls.invoice}/api/vatkey`;
  private _defaultTax$ = new ReplaySubject<TaxModel>(1);

  constructor(private http: HttpClient) {}

  // @Memoize(12 * 60 * 60 * 1000)
  getAll() {
    return this.http
      .get<TaxModel[]>(this.baseUrl)
      .pipe(tap((taxes: any) => this._defaultTax$.next(taxes.find((tax: any) => tax.description === '27%'))));
  }

  computeTaxAmount(param: { totalAmount: number; vatPercentage: number }) {
    return this.http.post<{ vatAmount: number }>(this.baseUrl, param);
  }

  get defaultTax$(): Observable<TaxModel> {
    return this._defaultTax$.asObservable();
  }
}
