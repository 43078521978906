import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { isFunction } from '../../utils/type-guard/is-function';
import { HandledHttpError } from '../exception/handled-http.error';
import { searchErrorCodeFunction } from './search-error-code.function';
import { showTranslatedErrorDialog } from './show-translated-error-dialog.function';
import { isNil } from '../../utils/type-guard/is-nil';
import { ErrorCenterService } from '../error-center.service';
import { showErrorDialog } from './show-error-dialog.function';

/**
 * amennyiben nem kezelt hibat adunk at akkor altalanos hibauzenet ir ki a default parameterek alapjan
 *
 * @param error
 * @param translateVariables
 * @param clickCallback
 * @param commonErrorDialogTitleTranslateKey
 * @param commonErrorDialogMessageTranslateKey
 * @param commonErrorDialogButtonTranslateKey
 */
export function maybeHandleHttpError(
  error: any,
  translateVariables?: { [key: string]: string | number },
  clickCallback?: NoopFunctionType,
  commonErrorDialogTitleTranslateKey: string = 'COMMON_ERROR_DIALOG.TITLE',
  commonErrorDialogMessageTranslateKey: string = 'COMMON_ERROR_DIALOG.TEXT',
  commonErrorDialogButtonTranslateKey: string = 'COMMON.ACTION_BUTTONS.OK'
) {
  /**
   * check is not handled error?
   * ez a vizsgalat csak akkor hozhat true-t ha gyermek osztalyrol van  szo mivel az ososztaly szuloje mar nem onmaga
   * es js prototype miatt igy lehet vizsgalni direktben a szulot
   */
  if (error instanceof HandledHttpError === false && error['__proto__'] instanceof HandledHttpError === false) {
    const remoteErrorCodes: { code: number; field?: string }[] = [];
    if (!isNil(error.error)) {
      if (!isNil(error.error['code'])) {
        remoteErrorCodes.push({ code: error.error['code'] });

        if (!isNil(error.error['developerMessages']) && Array.isArray(error.error['developerMessages'])) {
          error.error['developerMessages'].forEach((developerMessage) => {
            remoteErrorCodes.push({
              code: developerMessage['code'],
              field: developerMessage['messageField'],
            });
          });
        }
      } else if (!isNil(error.error['message'])) {
        const errorCode = error.error['message'].match(/^\[([0-9].*)\].*/);
        if (!isNil(errorCode) && Array.isArray(errorCode) && errorCode.length === 2) {
          remoteErrorCodes.push({ code: errorCode[1] });
        }
      }
    }

    if (remoteErrorCodes.length > 0) {
      searchErrorCodeFunction(remoteErrorCodes).subscribe((messages) => {
        if (messages.length === 0) {
          // ha  nem talaltunk kodot
          console.warn('Not found error code: ', remoteErrorCodes);
          showTranslatedErrorDialog(
            clickCallback,
            commonErrorDialogTitleTranslateKey,
            commonErrorDialogMessageTranslateKey,
            commonErrorDialogButtonTranslateKey
          );
        } else {
          const errorCenterService = ErrorCenterService.INSTANCE;
          // TODO sima showErrorDialog -t kene hasznalni!
          const messageBody = [] as string[];
          messages.forEach((message) => {
            let translateVariable: any;
            if (message.variable !== undefined) {
              translateVariable = { fieldName: errorCenterService.translateService.translate(`HTTP_ERROR.FIELDS.${message.variable}`) };
            }
            messageBody.push(errorCenterService.translateService.translate(message.message, translateVariable));
          });
          showErrorDialog(
            errorCenterService.matDialog,
            errorCenterService.translateService.translate(commonErrorDialogTitleTranslateKey),
            messageBody.join('<br/>'),
            errorCenterService.translateService.translate(commonErrorDialogButtonTranslateKey),
            clickCallback,
            undefined,
            true
          );
        }
      });
    } else {
      if (error.status <= 500 && error.status >= 400) {
        showTranslatedErrorDialog(clickCallback);
      } else if (isFunction(clickCallback)) {
        clickCallback();
      }
    }
  } else if (isFunction(clickCallback)) {
    clickCallback();
  }
}
