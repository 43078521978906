import { Component, HostListener, Inject, NgZone, Renderer2 } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import { BehaviorSubject, timer } from 'rxjs';
import { environment } from '../environments/environment';
import { MessageAddAction } from './app.state/action/message-add.action';
import { getRandomInt } from './modules/app-layout/menu/menu.component';
import { WINDOW } from './modules/common/window.token';
import { LoadOrdersAction } from './modules/order/action/load-orders.action';
import { UserState } from './modules/user/state/user.state';
import { isNil } from './modules/utils/type-guard/is-nil';

const EventSource = NativeEventSource || EventSourcePolyfill;

@Component({
  selector: 'tg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly messages$ = new BehaviorSubject<{ message: string; linkText?: string; linkClick?: () => void }[]>([]);
  private source!: EventSource;

  constructor(
    store: Store,
    actions$: Actions,
    translocoService: TranslocoService,
    @Inject(WINDOW) private window: Window,
    ngZone: NgZone,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'storage', this.xxx.bind(this));

    if (store.selectSnapshot(UserState.loggedIn)) {
      timer(0).subscribe(() => store.dispatch(new LoadOrdersAction()));
    }

    actions$.pipe(ofActionDispatched(MessageAddAction)).subscribe((action) => {
      this.messages$.next([
        {
          message: translocoService.translate(action.message),
          linkText: translocoService.translate(action.linkText),
          linkClick: action.linkClick,
        },
      ]);
    });

    timer(5000).subscribe(() => ngZone.runOutsideAngular(() => this.startMaintenanceModeFbRemoteWatcher()));
  }

  @HostListener('window:beforeunload')
  doSomething() {
    if (!isNil(this.source)) {
      this.source.close();
    }
  }

  private startMaintenanceModeFbRemoteWatcher(): void {
    if (environment.maintenanceModeFbUrl === null) {
      // disable
      return;
    }
    try {
      const isFunction = (functionToCheck: any) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

      const debounce = function (func: any, wait: any) {
        let timeout: any;
        let waitFunc: any;

        return function () {
          if (isFunction(wait)) {
            waitFunc = wait;
          } else {
            waitFunc = function () {
              return wait;
            };
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const context = this;
          // eslint-disable-next-line prefer-rest-params
          const args = arguments;
          const later = function () {
            timeout = null;
            func.apply(context, args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, waitFunc());
        };
      };

      // reconnectFrequencySeconds doubles every retry
      let reconnectFrequencySeconds = 1;
      // tslint:disable-next-line:prefer-const
      const reconnectFunc = debounce(
        () => {
          // tslint:disable-next-line
          setupEventSource();
          // Double every attempt to avoid overwhelming server
          reconnectFrequencySeconds *= 2;
          // Max out at ~1 minute as a compromise between user experience and server load
          if (reconnectFrequencySeconds >= 64) {
            reconnectFrequencySeconds = 64;
          }
        },
        function () {
          return reconnectFrequencySeconds * 1000;
        }
      );

      const setupEventSource = () => {
        this.source = new EventSource(environment.maintenanceModeFbUrl);
        this.source.onmessage = (e) => {
          // Handle even here
        };
        this.source.onopen = (e) => {
          // Reset reconnect frequency upon successful connection
          reconnectFrequencySeconds = 1;
        };
        this.source.onerror = (e) => {
          this.source.close();
          reconnectFunc();
        };
        this.source.addEventListener(
          'put',
          (e: any) => {
            const data = JSON.parse(e.data);
            if (data.data === true) {
              this.goToMaintenanceMode(this.window);
            }
          },
          false
        );
      };
      setupEventSource();
    } catch (ex) {
      console.error('EventSource error: ', ex);
    }
  }

  private goToMaintenanceMode(window: Window) {
    window.location.assign(`/maintenance-mode.html?rand=${getRandomInt(0, 9999999999)}`);
  }

  xxx($event: { key: string; newValue: string }) {
    if ($event.key === 'broadcastMessage') {
      const parsedValue: {
        command: 'refreshTab' | 'closeTab';
        uniqId: string;
      } = JSON.parse($event.newValue);
      if (parsedValue.uniqId !== (this.window as any)['__uniqId']) {
        switch (parsedValue.command) {
          case 'refreshTab':
            this.window.location.reload();
            break;

          case 'closeTab':
            this.window.close();
            break;
        }
      }
    }
  }
}
