import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { maybeHandleHttpError } from './maybe-handle-http-error.function';

/**
 * altalanos http error kezelo
 *
 * @param clickCallback
 */
export function commonHttpErrorHandle(clickCallback?: NoopFunctionType): (error: any) => void {
  return (error) => maybeHandleHttpError(error, undefined, clickCallback);
}
