<mat-toolbar color="primary">
  <button mat-icon-button (click)="matSidenav.toggle(!matSidenav.opened, 'touch')">
    <mat-icon>menu</mat-icon>
  </button>
  <h1 class="mobile-title" @fadeInEnter>{{ appTitle$ | async | transloco }}</h1>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="56">
  <mat-sidenav #matSidenav color="accent" mode="over" fixedInViewport="true" fixedTopGap="56" opened="false">
    <ul [ngClass]="first ? 'mt-2' : ''" *ngFor="let rootMenuItem of menu$ | async; let first = first">
      <li>
        <h3 *ngIf="$any(rootMenuItem)?.top?.length > 0 || $any(rootMenuItem).bottom?.length > 0; else linkTpl">
          {{ rootMenuItem.title | transloco }}
        </h3>
        <ng-template #linkTpl
          ><a matRipple routerLinkActive="active" (click)="onClickRootMenuItem(matSidenav, rootMenuItem)"
            >{{ rootMenuItem.title | transloco }}<mat-icon>keyboard_arrow_right</mat-icon></a
          ></ng-template
        >
        <ul>
          <ng-container *ngFor="let subMenuItem of rootMenuItem.top">
            <li *ngIf="subMenuItem.showContext$ === undefined || (subMenuItem._showContext$ | async)">
              <a matRipple routerLinkActive="active" (click)="onClickMenuItem(matSidenav, subMenuItem)"
                >{{ subMenuItem.title | transloco }}<mat-icon>keyboard_arrow_right</mat-icon></a
              >
            </li>
          </ng-container>
          <ng-container *ngFor="let subMenuItem of rootMenuItem.bottom">
            <li *ngIf="subMenuItem.showContext$ === undefined || (subMenuItem._showContext$ | async)">
              <a matRipple routerLinkActive="active" (click)="onClickMenuItem(matSidenav, subMenuItem)"
                >{{ subMenuItem.title | transloco }}<mat-icon>keyboard_arrow_right</mat-icon></a
              >
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
    <ul class="mt-2 mb-2 pl-0 without-padding">
      <li class="text-center">{{ browserName }}</li>
      <li class="text-center" style="font-size: 14px; color: rgba(0, 0, 0, 0.38)">Version {{ version }}</li>
    </ul>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="container mt-3 mb-4">
      <div [@routeAnimations]="outlet && outlet.activatedRouteData" [@.disabled]="disableAnimations">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
