import { Pipe, PipeTransform } from '@angular/core';
import { LiveAutoCompleteOptionsConfigModel } from '../model/live-auto-complete-options-config.model';
import { isNil } from '../../utils/type-guard/is-nil';
import { isFunction } from '../../utils/type-guard/is-function';

@Pipe({
  name: 'displaySelectedValue',
})
export class DisplaySelectedValuePipe<DATA_MODEL> implements PipeTransform {
  transform(value: DATA_MODEL, options: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>): any {
    if (!isNil(value)) {
      if (isFunction(options.displayFn)) {
        return options.displayFn(value);
      }
      return value;
    }
  }
}
