import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { fadeIn, fadeOut } from 'ng-animate';
import { Observable, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { fadeInEnter } from '../../../animations';
import { WINDOW } from '../../common/window.token';
import { UserMeModel } from '../../user/state/model/user-me.model';
import { UserState } from '../../user/state/user.state';
import { menuHandleAfterClick } from './menu-handle-after-click.function';
import { MenuService } from './menu.service';
import { MenuConfigModel } from './model/menu-config.model';
import { MenuWrapperModel } from './model/menu-wrapper.model';
import { SubMenuConfigModel } from './model/sub-menu-config.model';
import { ResetProfilePictureImageAction } from './state/action/reset-profile-picture-image.action';
import { ToggleMenuAction } from './state/action/toggle-menu.action';
import { MenuState } from './state/menu.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInEnter,
    trigger('openClose', [
      state('open', style({ display: 'block' })),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition('open => closed', useAnimation(fadeOut)),
      transition('closed => open', useAnimation(fadeIn)),
    ]),
    trigger('openCloseInline', [
      state('open', style({ display: 'inline-flex' })),
      state(
        'closed',
        style({
          opacity: 0,
          display: 'none',
        })
      ),
      transition('open => closed', useAnimation(fadeOut, { params: { timings: 300 } })),
      transition('closed => open', useAnimation(fadeIn)),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  static imageRandomNumber: number;
  // user + role -okhoz cső kell
  menuWrapper$: Observable<MenuWrapperModel>;
  chosenMenu!:
    | {
        chosenMenu: MenuConfigModel;
        chosenSubMenu: SubMenuConfigModel;
      }
    | any;
  opened!: boolean;
  @Select(UserState.me)
  readonly userMe$!: Observable<UserMeModel>;
  userImageMonogramMode = false;

  userImageLoadMode = false;
  private routerEventSubscription!: Subscription;
  private disableNextUrlDetect = false;
  version = environment.VERSION;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private menuService: MenuService,
    private store: Store,
    private injector: Injector,
    private actions$: Actions,
    @Inject(WINDOW) private window: Window
  ) {
    // add menu filter operators
    this.menuWrapper$ = this.menuService.getMenuObservable().pipe(
      tap((menu: MenuWrapperModel) => {
        // ha valtozik a menu valamiert valamilyen szinten akkor a jelenlegi menut megint meg kell keresni hisz uj referencia lesz ...
        this.setChoosenMenu(this.router.url, menu);
      })
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((event: any) => {
        this.setChoosenMenu(event.urlAfterRedirects, this.menuService.getMenuCurrentValue());
      });

    this.detectProfilePictureReset();
  }

  private setChoosenMenu(url: string, menu: MenuWrapperModel) {
    this.menuService
      .findSelectedMenuItemByCurrentUrl(url, menu)
      .pipe(take(1))
      .subscribe((chosenMenu) => {
        this.chosenMenu = chosenMenu.selectedMenus;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    this.urlDetector();
    this.store
      .select(MenuState.menuOpened)
      .pipe(untilDestroyed(this))
      .subscribe((menuOpened: boolean) => {
        this.opened = menuOpened;
        timer(0).subscribe(() => this.cdr.markForCheck());
      });
  }

  onMenuChange(chosenMenu: MenuConfigModel) {
    if (chosenMenu.outsideLink !== undefined) {
      this.window.open(chosenMenu.outsideLink, chosenMenu.target);
    }
    // ha a fő menüpontnak van route-ja, akkor odanavigálunk
    if (chosenMenu.route) {
      this.router.navigate([chosenMenu.route]);
    }
    // ha főmenünek nincs route-ja, de van legalább egy subtop menüje
    else if (chosenMenu.top && chosenMenu.top[0]) {
      this.router.navigate([chosenMenu.top[0].route]);
    }
    // ha főmenünek nincs route-ja, de van legalább egy subbottom menüje
    else if (chosenMenu.bottom && chosenMenu.bottom[0]) {
      this.router.navigate([chosenMenu.bottom[0].route]);
    }
  }

  onMenuToggle() {
    this.store.dispatch(new ToggleMenuAction());
  }

  onFloatMenuNavigate(subMenu: SubMenuConfigModel): void {
    menuHandleAfterClick(subMenu, this.injector);
    this.router.navigate([subMenu.route]);
  }

  onSelectSubMenu($event: SubMenuConfigModel) {
    this.disableNextUrlDetect = true;
    // this.chosenMenu = { ...this.chosenMenu, chosenSubMenu: $event };
    this.chosenMenu.chosenSubMenu = $event;
  }

  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
  }

  getImageRandom() {
    if (MenuComponent.imageRandomNumber === undefined) {
      this.generateImageRandomNumber();
    }
    return MenuComponent.imageRandomNumber;
  }

  onErrorUserImage() {
    this.userImageMonogramMode = true;
    this.userImageLoadMode = false;
    // this.cdr.markForCheck();
  }

  onLoadUserImage() {
    this.userImageMonogramMode = false;
    this.userImageLoadMode = false;
    // this.cdr.markForCheck();
  }

  private detectProfilePictureReset() {
    this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(ResetProfilePictureImageAction)).subscribe(() => {
      this.userImageMonogramMode = false;
      this.userImageLoadMode = true;
      this.generateImageRandomNumber();
      this.cdr.markForCheck();
    });
  }

  private urlDetector() {
    this.routerEventSubscription = this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        filter(() => {
          if (this.disableNextUrlDetect) {
            this.disableNextUrlDetect = false;
            return false;
          }
          return true;
        }),
        switchMap((event: NavigationEnd) => this.menuService.findSelectedMenuItemByCurrentUrl(event.url).pipe(take(1))),
        filter(
          (menu) =>
            this.chosenMenu.chosenMenu !== menu.selectedMenus.chosenMenu &&
            this.chosenMenu.chosenSubMenu !== menu.selectedMenus.chosenSubMenu
        )
      )
      .subscribe(
        (
          menu:
            | {
                selectedMenus: {
                  chosenMenu: MenuConfigModel;
                  chosenSubMenu: SubMenuConfigModel;
                };
                navigateUrl: string;
              }
            | any
        ) => {
          this.chosenMenu = menu.selectedMenus;
          this.cdr.markForCheck();
        }
      );
  }

  private generateImageRandomNumber() {
    MenuComponent.imageRandomNumber = getRandomInt(0, 9999999999);
    console.warn('generateImageRandomNumber', MenuComponent.imageRandomNumber);
  }

  onClickLogo() {
    this.menuService
      .findSelectedMenuItemByCurrentUrl('/')
      .pipe(take(1))
      .subscribe((menu: any) => {
        if (menu.navigateUrl === undefined) {
          this.router.navigate([
            menu.selectedMenus.chosenSubMenu !== undefined ? menu.selectedMenus.chosenSubMenu.route : menu.selectedMenus.chosenMenu.route,
          ]);
        } else {
          this.router.navigate([menu.navigateUrl]);
        }
      });
  }
}

export function getRandomInt(min: any, max: any) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
