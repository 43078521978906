import { animate, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut, zoomIn, zoomOut } from 'ng-animate';

export const fadeInEnter = trigger('fadeInEnter', [transition(':enter', useAnimation(fadeIn))]);
export const fadeOutLeave = trigger('fadeOutLeave', [transition(':leave', useAnimation(fadeOut))]);
export const fadeOut2XFastLeave = trigger('fadeOut2XFastLeave', [
  transition(
    ':leave',
    useAnimation(fadeOut, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const zoomInEnter = trigger('zoomInEnter', [transition(':enter', useAnimation(zoomIn))]);
export const zoomOutLeave = trigger('zoomOutLeave', [transition(':leave', useAnimation(zoomOut))]);
export const bounceOutUpLeave = trigger('bounceOutUpLeave', [transition(':leave', useAnimation(zoomOut))]);
export const fakeAnim = trigger('fakeAnim', [transition('* => *', animate('0s'))]);
export const zoomIn2XEnter = trigger('zoomIn2XEnter', [
  transition(
    ':enter',
    useAnimation(zoomIn, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
export const zoomOut2XLeave = trigger('zoomOut2XLeave', [
  transition(
    ':leave',
    useAnimation(zoomOut, {
      params: { timing: 0.5, delay: 0 },
    })
  ),
]);
