import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalQuestionContentComponent } from './modal-question-content/modal-question-content.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  declarations: [ModalQuestionContentComponent],
  exports: [ModalQuestionContentComponent],
})
export class ModalQuestionModule {}
