import { Injector } from '@angular/core';
import { isFunction } from '../../utils/type-guard/is-function';
import { SubMenuConfigModel } from './model/sub-menu-config.model';

export function menuHandleAfterClick(subMenu: SubMenuConfigModel, injector: Injector) {
  if (isFunction(subMenu.afterClicked)) {
    const cbArgs: any[] = [];
    if (Array.isArray(subMenu.afterClicked$Deps)) {
      subMenu.afterClicked$Deps.forEach((dep) => cbArgs.push(injector.get(dep)));
    }
    subMenu.afterClicked(...cbArgs);
  }
}
