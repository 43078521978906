import { HttpUrlEncodingCodec } from '@angular/common/http';

export class ParamsEncoder extends HttpUrlEncodingCodec {
  override encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  override encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
