import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderState } from '../../../order/order.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-menu-display',
  templateUrl: './menu-display.component.html',
  styleUrls: ['./menu-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDisplayComponent {
  readonly loading$ = new BehaviorSubject(true);
  backDaysCounter!: number;

  constructor(private http: HttpClient, private store: Store) {
    store
      .select(OrderState.orders)
      .pipe(
        untilDestroyed(this),
        map((orderTrials) => orderTrials[0])
      )
      .subscribe((orderTrial) => {
        this.backDaysCounter = moment(orderTrial.periodTo, 'YYYY-MM-DD').diff(moment(`${moment().format('YYYY-MM-DD')} 00:00`), 'days');
        this.loading$.next(false);
      });
  }
}
