import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { ErrorCenterService } from './modules/error-center/error-center.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private errorCenterService: ErrorCenterService) {}

  getTranslation(lang: string) {
    if(environment.production === false){
      console.log(`load translation: ${lang}`)
    }
    this.errorCenterService.loadTranslate(`assets/i18n/${lang}-http-error-codes.json`);
    return this.http.get<Translation>(`assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['hu', 'en'],
        defaultLang: 'hu',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: { allowEmpty: true },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
