import { SimpleChanges } from '@angular/core';
import { isNil } from '../type-guard/is-nil';

export function checkPropertyChange(key: string, changes: SimpleChanges): boolean {
  if (
    !isNil(changes[key]) &&
    ((changes[key].isFirstChange() && !isNil(changes[key].currentValue)) || changes[key].previousValue !== changes[key].currentValue)
  ) {
    return true;
  }
  return false;
}
