<div [ngClass]="formFieldClass">
  <mat-form-field [floatLabel]="floatLabel" [hideRequiredMarker]="hideRequiredMarker">
    <mat-label *ngIf="floatLabel !== 'never'">{{ label ?? optionsConfig.label | transloco }}</mat-label>
    <mat-select
      [formControl]="insideFormControl"
      [placeholder]="placeholder ?? optionsConfig.placeholder | transloco"
      [panelClass]="classList"
      [required]="required"
      [attr.name]="name"
      [compareWith]="defaultCompareFn"
      (selectionChange)="onSelectionChange()"
    >
      <mat-option *ngIf="panelAlwaysAbove === false">
        <!--disableScrollToActiveOnOptionsChanged should be set to true-->
        <ngx-mat-select-search
          #ngxMatSelectSearch
          [formControl]="searchFormControl"
          [placeholderLabel]="placeholder ?? optionsConfig.placeholder | transloco"
          [disableScrollToActiveOnOptionsChanged]="true"
          [searching]="$any(loading$ | async)"
          [noEntriesFoundLabel]="noEntriesFoundLabel$ | async | transloco"
        >
          <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger *ngIf="$any(selectedValue)?.id !== null">{{
        selectedValue | displaySelectedValue: optionsConfig
      }}</mat-select-trigger>
      <mat-option disabled="true" #notFoundMatOption *ngIf="firstLoading === false && (list$ | async)?.length === 0"
        ><b>{{ noEntriesFoundLabel$ | async | transloco }}</b></mat-option
      >
      <div
        class="infinty-scroll-wrapper"
        infiniteScroll
        [infiniteScrollDisabled]="infiniteScrollDisabled"
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        [fromRoot]="true"
        infiniteScrollContainer=".live-auto-complete-result-panel"
        (scrolled)="getNextBatch()"
      >
        <mat-option
          *ngFor="let item of list$ | async; let index = index"
          class="live-auto-complete-option"
          tgHoverState
          #hoverState="tgHoverState"
          [rrRawValue]="item"
          [value]="valueBind(item)"
          [disabled]="!(searchFormControl.value | isNil) && optionsConfig.optionDisabledBindFn(item, searchFormControl.value)"
          [ngClass]="(optionClassFn | isFunction) ? optionClassFn(item) : undefined"
        >
          <ng-container *ngIf="optionTplRef | isNil; else optionTpl">
            <span [innerHTML]="markSelectedText(optionsConfig.optionDisplayFn, item) | sanitizeHtml"></span>
          </ng-container>
          <ng-template #optionTpl>
            <span class="d-inline-block mt-3 mb-3"
              ><ng-container
                *ngTemplateOutlet="
                  optionTplRef;
                  context: { text: markSelectedText(optionsConfig.optionDisplayFn, item) | sanitizeHtml, item: item }
                "
              ></ng-container
            ></span>
          </ng-template>
        </mat-option>
        <mat-option
          class="infinty-scroll-loader"
          *ngIf="((list$ | async)?.length ?? 0) > 0 && remoteConfig.hasNextDataPage"
          [disabled]="true"
          >{{ 'LIVE_AUTO_COMPLETE.LOADING_ITEMS' | transloco }}</mat-option
        >
      </div>
      <mat-option *ngIf="panelAlwaysAbove === true">
        <!--disableScrollToActiveOnOptionsChanged should be set to true-->
        <ngx-mat-select-search
          #ngxMatSelectSearch
          [formControl]="searchFormControl"
          [placeholderLabel]="placeholder ?? optionsConfig.placeholder | transloco"
          [disableScrollToActiveOnOptionsChanged]="true"
          [searching]="$any(loading$ | async)"
          [noEntriesFoundLabel]="noEntriesFoundLabel$ | async | transloco"
        >
          <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
        </ngx-mat-select-search>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="insideFormControl.invalid">
      <tg-validation-messages [errors]="insideFormControl?.errors" [messages]="validationMessages"></tg-validation-messages>
    </mat-error>
    <ng-content select="mat-hint"></ng-content>
    <mat-progress-spinner diameter="16" color="accent" mode="indeterminate" *ngIf="showProgressSpinner$ | async"></mat-progress-spinner>
    <span matPrefix *ngIf="prefixIcon !== undefined"
      ><mat-icon>{{ prefixIcon }}</mat-icon></span
    >
    <span matSuffix
      ><ng-container *ngIf="loading"
        ><mat-progress-spinner @zoomIn2XEnter color="accent" mode="indeterminate"></mat-progress-spinner
      ></ng-container>
      <button
        *ngIf="
          !insideFormControl.disabled &&
          !loading &&
          ((selectedValue | isNil) === false || (insideFormControl?.value | isNil) === false) &&
            (((selectedValue | isString) && $any(selectedValue).length > 0) || (selectedValue | isString) === false)
        "
        mat-icon-button
        @zoomIn2XEnter
        class="clear-button"
        style="margin-left: 0.5rem"
        tabindex="-1"
        tgPreventClick
        (click)="onClickAutoCompleteClearButton()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        *ngIf="loading === false && customIconButton"
        type="button"
        mat-icon-button
        tabindex="-1"
        [matTooltip]="customIconButton?.tooltip ?? ''"
        (click)="onClickCustomIconButton()"
        tgPreventClick
      >
        <mat-icon>{{ customIconButton?.icon }}</mat-icon>
      </button></span
    >
  </mat-form-field>
</div>
