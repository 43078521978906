import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ValidationTemplateMessageModel } from '../model/validation-template-message.model';
import { BehaviorSubject } from 'rxjs';
import { VALIDATION_MESSAGES_CONFIG } from '../token/validation.provider.token';
import { ValidationMessageModel } from '../model/validation-message.model';
import { checkPropertyChange } from '../../utils/function/check-property-change.function';
import { isNil } from '../../utils/type-guard/is-nil';

let id = 0;

@Component({
  selector: 'tg-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessagesComponent implements AfterViewChecked, OnChanges {
  /**
   * csak az elso uzenetet jeleniti meg
   * (szamit a validatorok sorrendje!)
   *
   * @default true
   */
  @Input()
  firstMessageVisible = true;
  @Input()
  id = `validationComponent_${id++}`;
  @Input()
  messages!: ValidationMessageModel[];
  @Input()
  errors: any;
  @Input()
  @HostBinding('class.text-overflow-hidden')
  textOverflowHidden = true;
  // @ViewChildren(MatError, { read: ElementRef }) matErrors: QueryList<ElementRef>;
  errorMessages: BehaviorSubject<ValidationTemplateMessageModel[]> = new BehaviorSubject([] as ValidationTemplateMessageModel[]);
  checkedVisibleTooltip = false;

  constructor(
    @Inject(VALIDATION_MESSAGES_CONFIG)
    private validationMessages: ValidationMessageModel[],
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (checkPropertyChange('messages', changes)) {
      const value: ValidationMessageModel[] = changes['messages'].currentValue;
      this.validationMessages = this.validationMessages
        .filter((validationMessage) => value.findIndex((val) => validationMessage.errorKey === val.errorKey) === -1)
        .concat(value);
    }
    if (checkPropertyChange('errors', changes)) {
      const value: any = changes['errors'].currentValue;
      this.checkedVisibleTooltip = false;
      this.errorMessages.next(this.searchInMessageDictornary(value));
    }
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.offsetParent !== null && this.checkedVisibleTooltip === false) {
      this.checkedVisibleTooltip = true;
      this.cdr.detectChanges();
    }
  }

  private searchInMessageDictornary(validatorErrors: ValidationErrors): ValidationTemplateMessageModel[] {
    const msg: ValidationTemplateMessageModel[] = [];
    if (isNil(validatorErrors)) {
      return msg;
    }

    Object.keys(validatorErrors).forEach((key: string) => {
      const validationMessage = this.validationMessages.find((error) => error.errorKey === key);
      if (validationMessage !== undefined) {
        msg.push({
          validationMessageModel: validationMessage,
          formError: validatorErrors[key],
        });
      } else if (key === 'customError') {
        msg.push({
          validationMessageModel: {
            translateKey: 'CUSTOMERROR',
            errorKey: 'CUSTOMERROR',
          },
          formError: validatorErrors[key],
        });
      } else {
        console.warn(`${this.id} => Not found error message: ${key}`);
      }
    });
    return msg;
  }
}
