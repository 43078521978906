import { filter, map, take } from 'rxjs/operators';
import { ErrorCenterService } from '../error-center.service';
import { isNil } from '../../utils/type-guard/is-nil';

/**
 * error code keresese az atadott hiba alapjan amennyiben megfelel az altalanos hiba valasz strukturanak
 */
export function searchErrorCodeFunction(remoteErrorCodes: { code: number; field?: string } | { code: number; field?: string }[]) {
  if (!Array.isArray(remoteErrorCodes)) {
    remoteErrorCodes = [remoteErrorCodes];
  }
  return ErrorCenterService.INSTANCE.errorCodes$.pipe(
    filter((v) => !isNil(v)),
    take(1),
    map((errorCodes: any) => {
      const messages: { message: string; variable?: any }[] = [];
      (remoteErrorCodes as { code: number; field?: string }[]).forEach((remoteErrorCode) => {
        if (!isNil(errorCodes[remoteErrorCode.code])) {
          messages.push({
            message: `HTTP_ERROR_CODES.${errorCodes[remoteErrorCode.code]}`,
            variable: remoteErrorCode.field,
          });
        }
      });
      return messages;
    })
  );
}
