import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { ErrorCenterService } from '../error-center.service';
import { combineLatest } from 'rxjs';
import { showErrorDialog } from './show-error-dialog.function';
import { take } from 'rxjs/operators';

/**
 * Error dialog
 *
 * @param clickCallback
 * @param commonErrorDialogTitleTranslateKey
 * @param commonErrorDialogMessageTranslateKey
 * @param commonErrorDialogButtonTranslateKey
 * @param backdropClass
 */
export function showTranslatedErrorDialog(
  clickCallback?: NoopFunctionType,
  commonErrorDialogTitleTranslateKey = 'COMMON_ERROR_DIALOG.TITLE',
  commonErrorDialogMessageTranslateKey = 'COMMON_ERROR_DIALOG.TEXT',
  commonErrorDialogButtonTranslateKey = 'COMMON.ACTION_BUTTONS.OK',
  backdropClass?: string
) {
  const errorCenterService = ErrorCenterService.INSTANCE;

  combineLatest([
    errorCenterService.translateService.selectTranslate(commonErrorDialogTitleTranslateKey),
    errorCenterService.translateService.selectTranslate(commonErrorDialogMessageTranslateKey),
    errorCenterService.translateService.selectTranslate(commonErrorDialogButtonTranslateKey),
  ])
    .pipe(take(1))
    .subscribe(([title, message, button]) => {
      showErrorDialog(errorCenterService.matDialog, title, message, button, clickCallback, backdropClass);
    });

  // timer(0)
  //   .pipe(
  //     switchMap(() =>
  //       combineLatest([
  //         errorCenterService.translateService.selectTranslate(commonErrorDialogTitleTranslateKey),
  //         errorCenterService.translateService.selectTranslate(commonErrorDialogMessageTranslateKey),
  //         errorCenterService.translateService.selectTranslate(commonErrorDialogButtonTranslateKey),
  //       ])
  //     )
  //   )
  //   .subscribe(([title, message, button]) => {
  //     debugger
  //     showErrorDialog(errorCenterService.matDialog, title, message, button, clickCallback, backdropClass);
  //   });
}
