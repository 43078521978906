import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from '../user/state/user.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'tg-error-page404',
  templateUrl: './error-page404.component.html',
  styleUrls: ['./error-page404.component.scss'],
})
export class ErrorPage404Component {
  @Select(UserState.loggedIn)
  userLoggedIn$!: Observable<boolean>;

  alert(text: string) {
    alert(text);
  }
}
