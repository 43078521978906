import {
  LiveAutocompleteOptionDisplayBindFunction
} from './function-type/function/live-autocomple-option-display-bind.function';
import { isNil } from '../utils/type-guard/is-nil';

/**
 *
 * @param str szoveg amiben cserelni akarunk
 * @param index hanyadik karaktertol
 * @param replacement mire, amiben %%TEXT%% -et meg kell adni, igy jelolve hogy hova toltsuk az eredeti string-et
 * @param replacementOriginalLength mire hossza, amire azert van szukseg, mert lehet hogy mas a mire, mint a cserelendo hossz es akkor azt kezelni kell
 */
function replaceAt(str:any, index:any, replacement:any, replacementOriginalLength:any) {
  return (
    str.substr(0, index) +
    replacement.replace('%%TEXT%%', str.substr(index, replacementOriginalLength)) +
    str.substr(index + replacementOriginalLength)
  );
}

function getIndicesOf(searchStr:any, str:any, caseSensitive:any) {
  const searchStrLen = searchStr.length;

  if (searchStrLen === 0 || isNil(str)) {
    return [];
  }

  let index;
  let startIndex = 0;

  const indices = [];

  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

/**
 * Kijeloli a szovegbe a keresett string-et
 * @param optionDisplayFn
 * @param item
 */
export function markSelectedText<DATA_MODEL>(optionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<DATA_MODEL>, item: DATA_MODEL) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchText = (this as any).searchFormControl.value;
  let displayText = optionDisplayFn(item);
  getIndicesOf(searchText, displayText, false).forEach(
    (index, i) => (displayText = replaceAt(displayText, index + i * 7, `<b>%%TEXT%%</b>`, searchText.length))
  );
  return displayText;
}

export function commonMarkSelectedText(displayText: string, searchText: string) {
  getIndicesOf(searchText, displayText, false).forEach(
    (index, i) => (displayText = replaceAt(displayText, index + i * 7, `<b>%%TEXT%%</b>`, searchText.length))
  );
  return displayText;
}
