import { timer } from 'rxjs';

export const initGoogleServices = {
  analyticsLoading: false,
  adWordsLoading: false,
};

export const insertOutdatedBrowserDetectScript = (): void => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  // tslint:disable
  script.innerHTML = `(function() {
      outdatedBrowserRework({
      backgroundColor:'#143076',
        browserSupport: {
          Chrome: 117, // Includes Chrome for mobile devices
          Edge: 117,
          Safari: 11,
          'Mobile Safari': 11,
          Firefox: 60,
          Opera: false,
          // You could specify a version here if you still support IE in 2017.
          // You could also instead seriously consider what you're doing with your time and budget
          IE: false // 11
        },
        requireChromeOnAndroid: true,
        isUnknownBrowserOK: false,
        messages: {
          hu: {
            outOfDate:
              'Ez a böngésző elavult vagy nem támogatott',
            update: {
              web:
                "<b>Támogatott böngészők és verziók:</b><ul><li><b>Chrome</b> (<small>minimum verzió: 67</small>) <a href='https://www.google.com/chrome/'>letöltéshez kattints ide</a></li>" +
                "<li><b>Firefox</b> (<small>minimum verzió: 60</small>) <a href='https://www.mozilla.org/hu/firefox/new/'>letöltéshez kattints ide</a></li>" +
                "<li><b>Safari</b> (<small>minimum verzió: 11</small>) <a href='https://www.apple.com/macos/high-sierra/'>letöltéshez kattints ide</a></li></ul>",
                //"<li><b>Microsoft Edge</b> (<small>minimum verzió: 44</small>) <a href='https://www.microsoft.com/hu-hu/windows/microsoft-edge'>letöltéshez kattints ide</a></li></ul>",
              googlePlay: 'Google chrome telepítését vagy frissítését a Google play alkalmazásból teheted meg',
              appStore:
                '<b>Támogatott mobil böngészők:</b><ul><li><b>Chrome mobile</b> (<small>minimum verzió: 67</small>)</li><li><b>Safari mobile</b> (<small>minimum verzió: 11</small>)</li></ul>'
            },
            callToAction: 'Böngésző letöltése'
          }
        }
      });
    })();`;
  // tslint:enable
  document.head.appendChild(script);
};

/* tslint:disable */
const insertGoogleTrackingCode = (i: any, s: any, o: any, g: any, r: any, a?: any, m?: any): void => {
  i['GoogleAnalyticsObject'] = r;
  i[r] =
    i[r] ||
    function (): void {
      // eslint-disable-next-line prefer-rest-params
      (i[r].q = i[r].q || []).push(arguments);
    };
  i[r].l = 1 * (new Date() as any);
  a = s.createElement(o);
  m = s.getElementsByTagName(o)[0];
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
};

/* tslint:enable */
export const setGACode = (GA: string): void => {
  if (GA === undefined) {
    return;
  }
  if (initGoogleServices.analyticsLoading === false) {
    initGoogleServices.analyticsLoading = true;
    insertGoogleTrackingCode(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    timer(200).subscribe(() => setGACode(GA));
    return;
  }
  if (window !== undefined) {
    if ((window as any).ga === undefined) {
      timer(200).subscribe(() => setGACode(GA));
    } else {
      (window as any).ga('create', GA, 'auto');
    }
  }
};
