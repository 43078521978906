import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  private _title$ = new ReplaySubject<string>(1);

  get title$(): Observable<string> {
    return this._title$.asObservable();
  }
  set title(value: string) {
    this._title$.next(value);
  }
}
