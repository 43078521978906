import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { isDesktop } from '../main-config';
import { AppLayoutComponent } from './modules/app-layout/app-layout/app-layout.component';
import { HasAccessRouteGuard } from './modules/app-layout/menu/has-access-route.guard';
import { ErrorPage404Component } from './modules/error-page404/error-page404.component';
import { AuthGuard } from './modules/user/guard/auth.guard';
import { NotAuthGuard } from './modules/user/guard/not-auth.guard';
import { RegisteredUserGuard } from './modules/user/guard/registered-user.guard';
import { ROUTE_SETTINGS, ROUTE_SUBSCRIPTION_PROCESS, ROUTE_USER_LOGIN } from './modules/user/route.paths';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/invoice/dashboard',
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard, RegisteredUserGuard, HasAccessRouteGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/invoice/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'invoice',
        loadChildren: () => import('./modules/invoice/invoice.module').then((m) => m.InvoiceModule),
      },
      {
        path: 'my-subscription',
        loadChildren: () => import('./modules/my-subscription/my-subscription.module').then((m) => m.MySubscriptionModule),
      },
      {
        path: ROUTE_SUBSCRIPTION_PROCESS,
        loadChildren: () => import('./modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
      },
      {
        path: ROUTE_SETTINGS,
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'user/me',
        loadChildren: () => import('./modules/user/me/me.module').then((m) => m.MeModule),
      },
      {
        path: 'user/nav-registration',
        loadChildren: () => import('./modules/user/nav-registration/nav-registration.module').then((m) => m.NavRegistrationModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
    ],
  },
  // {
  //   path: 'mock-start',
  //   component: MockstartComponent
  // },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: ROUTE_USER_LOGIN,
    loadChildren: () => import('./modules/user/login/login.module').then((m) => m.LoginModule),
    canActivate: [NotAuthGuard],
  },
  {
    path: '**',
    component: ErrorPage404Component,
  },
];

const routerConfig: ExtraOptions = {
  useHash: true,
  relativeLinkResolution: 'legacy',
};
if (!isDesktop) {
  routerConfig.preloadingStrategy = PreloadAllModules;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
