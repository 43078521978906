import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { UserState } from '../../user/state/user.state';
import { WINDOW } from '../window.token';

@Pipe({
  name: 'addUserIdToIcon',
  pure: false,
})
export class AddUserIdToIconPipe implements PipeTransform {
  constructor(private store: Store, @Inject(WINDOW) private window: Window) {}

  transform(url: string): any {
    if (url.indexOf(':userId') > -1) {
      const splittedHref = this.window.location.href.split('/');
      return `${environment.production === false ? `${splittedHref[0]}//${splittedHref[2]}` : ''}${url.replace(
        ':userId',
        this.store.selectSnapshot(UserState.userId) as any
      )}?access_token=${this.store.selectSnapshot(UserState.accessToken)}&referenceid&latestversion=true`;
    }
    return url;
  }
}
