import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '../../type-guard/is-nil';
import { isString } from '../../type-guard/is-string';

@Pipe({
  name: 'isString',
})
export class IsStringPipe implements PipeTransform {
  transform(value: any): boolean {
    return isString(value);
  }
}
