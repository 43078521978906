<div class="sub-menu" [ngClass]="{ opened: opened }">
  <!--Felső rész: ikon + kiválasztott főmenü neve-->
  <header [ngClass]="{ closed: !opened }">
    <div class="title d-flex align-items-center">
      <div
        class="tg-icon circle"
        [matTooltip]="opened === false ? (chosenMenu?.chosenMenu?.title | transloco) : ''"
        [ngClass]="'icon-' + chosenMenu?.chosenMenu?.icon"
      ></div>
      <div class="text" [@openClose]="opened ? 'open' : 'closed'">
        {{ chosenMenu?.chosenMenu?.title | transloco }}
      </div>
    </div>
    <!--Fix magasságúnak kell lennie a Próbaidőszak szövegnek, hogy ne csúszkáljon a
    menüpontwrapper magassága (CLOUD-363)-->
    <div class="custom-area w-100" *ngIf="opened">
      <div class="d-flex flex-row align-items-start" [@fadeInEnter] *ngIf="customAreaCmpPortal">
        <ng-template [cdkPortalOutlet]="customAreaCmpPortal"></ng-template>
      </div>
    </div>
  </header>

  <!--Submenü elemek-->
  <div class="menu-items" [ngClass]="{ opened: opened }">
    <!--Felső menüelemek-->
    <ng-container *ngFor="let item of chosenMenu?.chosenMenu?.top; trackBy: trackBySubMenuModelFn">
      <div
        class="menu-item-wrapper"
        *ngIf="item.showContext$ === undefined || (item._showContext$ | async)"
        [@fadeInEnter]
        (click)="onClickItem(item)"
        [ngClass]="{ active: item.title === chosenMenu?.chosenSubMenu?.title }"
      >
        <div class="menu-item" [matTooltip]="opened === false ? (item.title | transloco) : ''">
          <div class="tg-icon" [ngClass]="'icon-' + item.icon"></div>
          <div class="menu-title" [@openClose]="opened ? 'open' : 'closed'">
            {{ item.title | transloco }}
          </div>
          <div class="right-border" [@openClose]="opened ? 'open' : 'closed'"></div>
        </div>
      </div>
    </ng-container>

    <!--Vízszintes elválasztó vonal-->
    <div *ngIf="visibleBottomDivider$ | async" class="horizontal-line" [ngClass]="{ opened: opened }"></div>

    <!--Alsó menüelemek-->
    <ng-container *ngFor="let item of chosenMenu?.chosenMenu?.bottom; trackBy: trackBySubMenuModelFn">
      <div
        class="menu-item-wrapper"
        *ngIf="item.showContext$ === undefined || (item._showContext$ | async)"
        (click)="onClickItem(item)"
        [ngClass]="{ active: item.title === chosenMenu?.chosenSubMenu?.title }"
      >
        <div class="menu-item" [matTooltip]="opened === false ? (item.title | transloco) : ''">
          <div class="tg-icon" [ngClass]="['icon-' + item.icon, opened?'opened':'']"></div>
          <div class="menu-title" [@openClose]="opened ? 'open' : 'closed'">
            {{ item.title | transloco }}
          </div>
          <div class="right-border"></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="bottom-items" [ngClass]="{ 'closed-width': !opened }">
    <!-- Ki-be nyitó-záró elem -->
    <div class="menu-toggle">
      <button mat-icon-button (click)="onMenuToggle()">
        <div class="tg-icon toggle-icon icon-e90e"></div>
      </button>
    </div>
  </div>
</div>
