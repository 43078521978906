import { Component, NgModule } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from '../user/state/user.state';
import { Observable } from 'rxjs';
import { ErrorPage404Component } from './error-page404.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ErrorPage404Component],
  imports: [CommonModule, TranslocoModule, MatButtonModule, RouterModule],
  exports: [ErrorPage404Component],
})
export class ErrorPage404Module {}
