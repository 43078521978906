import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalQuestionContentDialogModel } from '../model/modal-question-content-dialog.model';
import { isNil } from '../../../utils/type-guard/is-nil';

@Component({
  selector: 'tg-modal-question-content',
  templateUrl: './modal-question-content.component.html',
  styleUrls: ['./modal-question-content.component.scss'],
})
export class ModalQuestionContentComponent {
  constructor(
    // public dialogRef: MatDialogRef<ModalQuestionContentComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: ModalQuestionContentDialogModel
  ) {
    if (isNil(data.hasCancel)) {
      data.hasCancel = false;
    }
  }
}
