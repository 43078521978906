/* eslint-disable */
import { isNil } from '../type-guard/is-nil';

const pSlice = Array.prototype.slice;
const supportsArgumentsClass =
  (function () {
    // eslint-disable-next-line prefer-rest-params
    return Object.prototype.toString.call(arguments);
  })() === '[object Arguments]';

function supported(object: unknown): boolean {
  return Object.prototype.toString.call(object) === '[object Arguments]';
}

function unsupported(object: any): boolean {
  return (
    (object &&
      typeof object === 'object' &&
      typeof object.length === 'number' &&
      Object.prototype.hasOwnProperty.call(object, 'callee') &&
      !Object.prototype.propertyIsEnumerable.call(object, 'callee')) ||
    false
  );
}

const objectKeys = typeof Object.keys === 'function' ? Object.keys : shim;
const isArguments = supportsArgumentsClass ? supported : unsupported;

function shim(obj: any): string[] {
  const keys = [];
  for (const key in obj) {
    keys.push(key);
  }
  return keys;
}

export function deepEqual(actual: unknown, expected: unknown, opts?: Record<string, unknown>): boolean {
  if (!opts) {
    opts = {};
  }
  // 7.1. All identical values are equivalent, as determined by ===.
  if (actual === expected) {
    return true;
  } else if (actual instanceof Date && expected instanceof Date) {
    return actual.getTime() === expected.getTime();

    // 7.3. Other pairs that do not both pass typeof value == 'object',
    // equivalence is determined by ==.
  } else if (!actual || !expected || (typeof actual !== 'object' && typeof expected !== 'object')) {
    return opts['strict'] ? actual === expected : actual === expected;

    // 7.4. For all other Object pairs, including Array objects, equivalence is
    // determined by having the same number of owned properties (as verified
    // with Object.prototype.hasOwnProperty.call), the same set of keys
    // (although not necessarily the same order), equivalent values for every
    // corresponding key, and an identical 'prototype' property. Note: this
    // accounts for both named and indexed properties on Arrays.
  } else {
    return objEquiv(actual, expected, opts);
  }
}

function isBuffer(x: any): boolean {
  if (!x || typeof x !== 'object' || typeof x.length !== 'number') {
    return false;
  }
  if (typeof x.copy !== 'function' || typeof x.slice !== 'function') {
    return false;
  }
  if (x.length > 0 && typeof x[0] !== 'number') {
    return false;
  }
  return true;
}

function objEquiv(a: any, b: any, opts: Record<string, unknown>): boolean {
  let i, key;
  if (isNil(a) || isNil(b)) {
    return false;
  }
  // an identical 'prototype' property.
  if ((a as any).prototype !== (b as any).prototype) {
    return false;
  }
  // ~~~I've managed to break Object.keys through screwy arguments passing.
  //   Converting to array solves the problem.
  if (isArguments(a)) {
    if (!isArguments(b)) {
      return false;
    }
    a = pSlice.call(a);
    b = pSlice.call(b);
    return deepEqual(a, b, opts);
  }
  if (isBuffer(a)) {
    if (!isBuffer(b)) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
  let ka;
  let kb;
  try {
    ka = objectKeys(a);
    kb = objectKeys(b);
  } catch (e) {
    // happens when one is a string literal and the other isn't
    return false;
  }
  // having the same number of owned properties (keys incorporates
  // hasOwnProperty)
  if (ka.length !== kb.length) {
    return false;
  }
  // the same set of keys (although not necessarily the same order),
  ka.sort();
  kb.sort();
  // ~~~cheap key test
  for (i = ka.length - 1; i >= 0; i--) {
    if (ka[i] !== kb[i]) {
      return false;
    }
  }
  // equivalent values for every corresponding key, and
  // ~~~possibly expensive deep test
  for (i = ka.length - 1; i >= 0; i--) {
    key = ka[i];
    if (!deepEqual(a[key], b[key], opts)) {
      return false;
    }
  }
  return typeof a === typeof b;
}
