import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InputUserDataFormModel } from '../user/registration/input-user-data/model/input-user-data-form.model';
import { ProcessInstanceModel } from './state/model/process-instance.model';
import { TaskModel } from './state/model/task.model';

export const processCompleteUrl = `${environment.apiUrls.processUrl}/rest/task/:taskId/complete`;
// ezen url-nel sosincsen auth token!
export const processSubmitFormUrl = `${environment.apiUrls.processUrl}/rest/task/:taskId/submit-form`;
export const taskDataByProcessInstanceIdUrl = `${environment.apiUrls.processUrl}/rest/task?processInstanceId=:processInstanceId`;
export const taskDataByTenantIdUrl = `${environment.apiUrls.processUrl}/rest/task?tenantIdIn=:tenantId`;
export const taskDataByProcessDefinitionKey = `${environment.apiUrls.processUrl}/rest/task?processDefinitionKey=:processDefinitionKey`;
export const refreshProcessInstanceData = `${environment.apiUrls.processUrl}/rest/process-instance/:processInstanceId`;
export const processMessagesUrl = `${environment.apiUrls.processUrl}/rest/message`;

export const mockOnBoardingStartUrl = `${environment.apiUrls.processUrl}/rest/process-definition/key/onboardingContactPerson/start`;
// export const mockOnBoardingConfirmUrl = `${environment.apiUrls.processUrl}/api/message/mailconfirm/process-instance/:processInstanceId`;
export const mockProcessInstanceUrl = `${environment.apiUrls.processUrl}/rest/process-instance?superProcessInstance=:processInstanceId`;

@Injectable({ providedIn: 'root' })
export class ProcessService {
  constructor(private readonly http: HttpClient /*, private readonly tokenInterceptor: TokenInterceptor*/) {}

  // refreshUserTask(assignee: string): Observable<TaskModel> {
  //   return this.http
  //     .get<TaskModel>(refreshUserTaskUrl.replace(':assignee', assignee))
  //     .pipe(map(tasks => (Array.isArray(tasks) && tasks.length > 0 ? tasks[0] : null)));
  // }

  refreshProcessInstance(processInstanceId: string): Observable<ProcessInstanceModel> {
    return this.http.get<ProcessInstanceModel>(refreshProcessInstanceData.replace(':processInstanceId', processInstanceId));
  }

  taskData(tenantId: string): Observable<TaskModel> {
    return this.http
      .get<any[]>(taskDataByTenantIdUrl.replace(':tenantId', tenantId))
      .pipe(map((tasks) => (Array.isArray(tasks) && tasks.length > 0 ? tasks[0] : undefined)));
  }

  taskDataByProcessInstanceId(processInstanceId: string): Observable<TaskModel> {
    return this.http
      .get<TaskModel>(taskDataByProcessInstanceIdUrl.replace(':processInstanceId', processInstanceId), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((tasks: any) => tasks[0]));
  }

  taskDataByProcessDefinitionKey(processDefinitionKey: string) {
    return this.http
      .get<TaskModel>(taskDataByProcessDefinitionKey.replace(':processDefinitionKey', processDefinitionKey))
      .pipe(map((tasks: any) => tasks[0] as TaskModel));
  }

  completeInputUserData(taskId: string, form: InputUserDataFormModel) {
    // this.tokenInterceptor.excludeNext = [{ value: processSubmitFormUrl.replace(':taskId', '.*'), regexp: true }];
    return this.http.post(
      `${processSubmitFormUrl.replace(':taskId', taskId)}`,
      {
        variables: {
          userInData: {
            value: `{"lastname":"${form.lastName}","firstname":"${form.firstName}"}`,
            type: 'json',
          },
          password: {
            value: form.password,
            type: 'String',
            valueInfo: {
              transient: true,
            },
          },
          company: {
            value: `{ "companyname": "${form.companyname}", "taxnumber":"${form.taxnumber}","zip":"${form.zip}","housenumber":"${form.housenumber}","city":"${form.city}","street":"${form.street}"}`,
            type: 'json',
          },
        },
        withVariablesInReturn: 'true',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  // completeCompanyData(taskId: string, form: InputCompanyDataFormModel) {
  //   return this.http.post(
  //     `${processCompleteUrl.replace(':taskId', taskId)}`,
  //     {
  //       variables: {
  //         company: {
  //           value: `${JSON.stringify({ ...form, country: 'HU' })}`,
  //           // value: `{"companyname":"${form.companyname}","taxnumber":"${form.taxnumber}","zip":"${
  //           //   form.zip
  //           // }","country":"HU","housenumber":"${form.housenumber}","city":"${form.city}","street":"${form.street}"}`,
  //           type: 'json'
  //         }
  //       }
  //     },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   );
  //   // .pipe(tap(() => this.http.get(`${environment.apiUrls.processUrl}/rest/task/${taskId}/form-variables`).subscribe(w => console.warn(w))));
  // }

  completeMaxAmount(taskId: string, maxAmount: number) {
    return this.http.post(`${processCompleteUrl.replace(':taskId', taskId)}`, {
      variables: {
        maxAmount: { value: maxAmount, type: 'string' },
      },
    });
  }

  message(json: any) {
    return this.http.post(processMessagesUrl, json);
  }

  registration(randomNumber: number, randomEmail: string, type: string) {
    return this.http.post<{
      links: {
        method: string;
        href: string;
        rel: string;
      }[];
      id: string;
      definitionId: string;
      businessKey: string;
      caseInstanceId: string;
      ended: boolean;
      suspended: boolean;
      tenantId: string;
      variables: {
        product: {
          type: string;
          value: string;
          valueInfo: any;
        };
        email: {
          type: string;
          value: string;
          valueInfo: any;
        };
      };
    }>(
      mockOnBoardingStartUrl,
      {
        variables: {
          email: { value: randomEmail, type: 'String' },
          product: { value: type, type: 'String' },
        },
        withVariablesInReturn: 'false',
        businessKey: randomNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // .pipe(
    // delay(1000),
    // switchMap(processInstance => {
    //   return this.http.get(mockProcessInstanceUrl.replace(':processInstanceId', processInstance.id),{
    //     headers: {
    //       'Authorization':
    //         'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJhbGwiXSwiZXhwIjoxNTM2NjMyMjY3LCJqdGkiOiJhMjY5NTQwOC0xN2JlLTRhZWEtYTM2Ny1hMTJhODU4NjZiNjgiLCJjbGllbnRfaWQiOiJmcm9udGVuZCJ9.Vhn7dBlDpoWlE-e-lwkBBK1-ZVcsJLBuDb1bHwublA_ib62HG6ZWmuvfCt2KsFF2NfILgP7ZZcuQXDTWTSM6wApmh2757_fGsXfyjWW9XlLU3sWgTCGeYNT42HQbMv5yLx_xi4H656pQvZUJcMqElFS-AgbqFKQFMVx8zuL6NSvjAS9ZlfP4PDGAx9nOm8aRkY-GgZ4r39TqCw979vNR2fcKfxK8nEAN0C0aX2Rt4JaVysogZSSPa4TWdphSj-ursNvluZEgYT9Sf1uBEdXXtcHUpX_nyM8nNYWznzDR50wwn63I0PD1haZRWCAfPvX_Q0pMbIhVA_OHjXrhVd34og'
    //     }
    //   }).pipe(
    //     map(
    //       (
    //         v: {
    //           links: string[];
    //           id: string;
    //           definitionId: string;
    //           businessKey: string;
    //           caseInstanceId: string;
    //           ended: boolean;
    //           suspended: boolean;
    //           tenantId: string;
    //         }[]
    //       ) => {
    //         processInstance.id = v[0].id;
    //         return processInstance;
    //       }
    //     ),
    //     delay(1000)
    //   );
    // })
    // );
  }

  // mockOnBoardingConfirmUrl(processInstanceId: string) {
  //   return this.http
  //     .get<void>(mockOnBoardingConfirmUrl.replace(':processInstanceId', processInstanceId), {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     .pipe(delay(1000));
  // }
}
