import { Pipe, PipeTransform } from '@angular/core';

/**
 * Monogramot állít elő a két megadott szóból. Az elsőre kell rátenni a pipe-ot, a másodikat paraméternek kell
 * megadni
 * Pl: 'Teszt' | monogram:'Elemér'  ===>  'TE'
 */
@Pipe({ name: 'monogram' })
export class MonogramPipe implements PipeTransform {
  transform(firstWord: string, secondWord: string): string | undefined {
    if (firstWord && secondWord) {
      return `${firstWord[0]}${secondWord[0]}`;
    } else {
      return;
    }
  }
}
