import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { isBoolean } from '../../../utils/type-guard/is-boolean';
import { ToggleMenuAction } from './action/toggle-menu.action';
import { ToggleSubMenuAction } from './action/toggle-sub-menu.action';
import { MenuStateModel } from './model/menu-state.model';
import { Injectable } from '@angular/core';

const defaultState: MenuStateModel = {
  menuOpened: false,
  subMenuOpened: true,
};

@State<MenuStateModel>({
  name: 'menu',
  defaults: defaultState,
})
@Injectable()
export class MenuState implements NgxsOnInit {
  @Selector()
  static menuOpened(state: MenuStateModel) {
    // TODO tg storage login utan kozvetlen ha nincs state beolvassa de nem tarolja le
    // ha még nem töltött be a default state -> default értéket adjuk vissza
    if (!isBoolean(state['menuOpened'])) {
      return false;
    }
    return state.menuOpened !== undefined ? state.menuOpened : false;
  }

  @Selector()
  static subMenuOpened(state: MenuStateModel) {
    // TODO tg storage login utan kozvetlen ha nincs state beolvassa de nem tarolja le
    // ha még nem töltött be a default state -> default értéket adjuk vissza
    if (!isBoolean(state['subMenuOpened'])) {
      return true;
    }
    return state.subMenuOpened !== undefined ? state.subMenuOpened : false;
  }

  @Action(ToggleMenuAction)
  toggleMenu({ patchState, getState }: StateContext<MenuStateModel>) {
    const state = getState();
    let opened = true;
    if (Object.keys(state).indexOf('menuOpened') > -1) {
      opened = !state.menuOpened;
    }

    return patchState({ menuOpened: opened });
  }

  @Action(ToggleSubMenuAction)
  toggleSubMenu({ patchState, getState }: StateContext<MenuStateModel>) {
    const state = getState();
    let opened = false;
    if (Object.keys(state).indexOf('subMenuOpened') > -1) {
      opened = !state.subMenuOpened;
    }
    return patchState({ subMenuOpened: opened });
  }

  ngxsOnInit(ctx?: StateContext<MenuStateModel>): void | any {
    const state = ctx?.getState();
    if (state !== undefined) {
      if (Object.keys(state).indexOf('menuOpened') === -1) {
        return ctx?.setState(defaultState);
      }
    }
  }
}
