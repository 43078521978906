import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AddUseIdToIconPipeModule } from '../../common/add-use-id-to-icon-pipe/add-use-id-to-icon-pipe.module';
import { MonogramPipeModule } from '../../common/monogram-pipe/monogram-pipe.module';
import { PreventClickModule } from '../../common/prevent-click/prevent-click.module';
import { MenuDisplayModule as SubscriptionMenuDisplayModule } from '../../subscription/menu-display/menu-display.module';
import { MenuIconDirective } from './menu-icon.directive';
import { MenuComponent } from './menu.component';
import { SubMenuModule } from './sub-menu/sub-menu.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,

    SubMenuModule,
    SubscriptionMenuDisplayModule,
    MonogramPipeModule,
    MatProgressSpinnerModule,
    AddUseIdToIconPipeModule,
    PreventClickModule,
  ],
  declarations: [MenuComponent, MenuIconDirective],
  exports: [MenuComponent],
})
export class MenuModule {}
