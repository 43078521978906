<ng-container *ngIf="firstMessageVisible; else allMessage">
  <ng-container *ngIf="(errorMessages | async)![0] as message">
    <div>
      <ng-template [ngIf]="message.validationMessageModel.errorKey === 'CUSTOMERROR'" [ngIfElse]="simpleMode">
        <span>{{ message.formError }}</span>
      </ng-template>
      <ng-template #simpleMode>
        <span>{{ message.validationMessageModel.translateKey | transloco: message.formError }}</span>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
<ng-template #allMessage>
  <!-- TODO atvezetni a fenti valtozasokat vagy kirakni egy kulon componentbe -->
  <div *ngFor="let message of errorMessages | async">
    {{ message.validationMessageModel.translateKey | transloco: message.formError }}
  </div>
</ng-template>
