import { Observable } from 'rxjs';
import { isNil } from '../../type-guard/is-nil';

export const isPresentValue =
  () =>
  <T>(source: Observable<T>) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next(x): void {
          if (!isNil(x)) {
            observer.next(x);
          }
        },
        error(err): void {
          observer.error(err);
        },
        complete(): void {
          observer.complete();
        },
      })
    );
