import {
  Attribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { Sort, SortDirection } from '@angular/material/sort';
import { LiveAutoCompleteBase } from './live-auto-complete.base';
import { MatSelect } from '@angular/material/select';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { LiveAutocompleteOptionClassesBindFunction } from '../function-type/function/live-autocomple-option-classes-bind.function';
import { LiveAutoCompleteOptionsConfigModel } from '../model/live-auto-complete-options-config.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, Store } from '@ngxs/store';
import { RawValue } from '../raw-value.directive';
import { markSelectedText } from '../mark-selected-text.function';
import { fadeInEnter, fadeOut2XFastLeave, zoomIn2XEnter, zoomOut2XLeave } from '../../../animations';
import { MatOption } from '@angular/material/core';
import { FloatLabelType } from '@angular/material/form-field';
import { BaseCrudServiceInterface } from '../../common/base-crud-service.interface';
import { isNil } from '../../utils/type-guard/is-nil';
import { WINDOW } from '../../common/window.token';
import { ValidationMessageModel } from '../../validating/model/validation-message.model';
import { deepEqual } from '../../utils/function/deep-equal';
import { isFunction } from '../../utils/type-guard/is-function';
import { isNumeric } from '../../utils/type-guard/is-numeric';
import { ENTITY_SERVICE_TOKEN } from './entity-token';
import { isString } from '../../utils/type-guard/is-string';
import { commonHttpErrorHandle } from '../../error-center/function/common-http-error-handle.function';

export function isObject(item: any): boolean {
  return item && !Array.isArray(item) && typeof item === 'object';
}

@UntilDestroy()
// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'tg-live-auto-complete',
  templateUrl: './live-auto-complete.component.html',
  styleUrls: ['./live-auto-complete.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [zoomIn2XEnter, zoomOut2XLeave, fadeInEnter, fadeOut2XFastLeave],
})
export class LiveAutoCompleteComponent<DATA_MODEL> extends LiveAutoCompleteBase<DATA_MODEL> implements OnInit, OnChanges {
  @ViewChildren('newMatOptionButton') newMatOptionButton!: QueryList<MatOption>;
  @ViewChildren('notFoundMatOption') notFoundMatOption!: QueryList<MatOption>;
  markSelectedText = markSelectedText;
  @ViewChild(MatSelect, { static: true })
  readonly matSelect!: MatSelect;
  @ViewChild('ngxMatSelectSearch', { static: true })
  readonly ngxMatSelectSearch!: { adjustScrollTopToFitActiveOptionIntoView: () => void };
  @Input() floatLabel!: FloatLabelType;
  @Input() hideRequiredMarker!: boolean;
  @Input() formFieldClass!: string;
  @Input() label?: string;
  @Input() name!: string;
  @Input() notFoundEntityLabel = 'LIVE_AUTO_COMPLETE.NOT_FOUND_RESULTS';
  @Input()
  placeholder?: string;
  @Input()
  optionTplRef!: TemplateRef<any>;
  @Input()
  optionClassFn!: LiveAutocompleteOptionClassesBindFunction<DATA_MODEL>;
  /**
   * remote rendezes iranya
   */
  @Input()
  sortDirection: SortDirection = 'asc';
  @Input()
  database!: BaseCrudServiceInterface<DATA_MODEL>;
  /**
   * Ha nincs megadva database, akkor kotelezo az entity service-t megadni!
   */
  @Input()
  entityService?: BaseCrudServiceInterface<DATA_MODEL>;
  @Input()
  databaseCallback!: (sort: Sort, page: PageEvent, simpleAllFilterValue: string) => Observable<DATA_MODEL[]>;
  @Input() formControlRef: FormControl = new FormControl();
  @Output() readonly hasOneCheckResponse = new EventEmitter<any>();
  @Output() override readonly openSearchPanel = new EventEmitter<void>();
  @Output() readonly selectionChange = new EventEmitter<DATA_MODEL>();
  @HostBinding('class.show-progress-spinner')
  showProgressSpinnerHostClass = false;
  override remoteConfig!: PageEvent & { hasNextDataPage: boolean; scrollFilterValue: string };
  readonly showProgressSpinner$ = new BehaviorSubject(false);
  readonly insideFormControl = new FormControl();
  defaultCompareFn = this.defaultCompare.bind(this);
  private hasOneCheck$!: Subscription;
  private disableNextFormControlRefValueChange = false;
  /**
   * Azert kell tarolni, mert eloforuldhat hogy a 'disableNextFormControlRefValueChange' flag-et atbillentjuk,
   * viszont kivulrol hamarabb valtozik a 'formControlRef', igy a FormControl-ok osszekotese bukna, viszont ha letaroljuk
   * a flag-hez az erteket is akkor tudunk vizsgalodni...
   */
  private disableNextFormControlRefValueChangeValue: unknown;

  @Input() panelAlwaysAbove = false;

  @Input() showLoader = false;
  loading = false;

  @Input() customIconButton?: { tooltip: string; icon: string };
  @Output() clickCustomIconButton = new EventEmitter<void>();

  @Output()
  readonly clear = new EventEmitter<void>();

  @Input() prefixIcon?: string;

  constructor(
    cdr: ChangeDetectorRef,
    @Attribute('classList') public classList: string,
    private store: Store,
    ngZone: NgZone,
    @Inject(WINDOW) window: Window,
    @Optional() @Inject(ENTITY_SERVICE_TOKEN) private injectedEntityService: unknown,
    private actions$: Actions
  ) {
    super(cdr, ngZone, window);

    if (isNil(this.classList)) {
      this.classList = '';
    }

    this.classList = `${this.classList}${this.classList.length > 0 ? ' ' : ''}mat-select`;
    if (this.classList.indexOf('live-auto-complete-result-panel') === -1) {
      this.classList = `live-auto-complete-result-panel ${this.classList}`;
    }

    this.showProgressSpinner$.subscribe((v) => (this.showProgressSpinnerHostClass = v));
  }

  private _validationMessages!: ValidationMessageModel[];

  get validationMessages(): ValidationMessageModel[] {
    const optionsValidationMessages =
      !isNil(this.optionsConfig) && Array.isArray(this.optionsConfig.validatorMessages) ? this.optionsConfig.validatorMessages : [];

    return [...(Array.isArray(this._validationMessages) ? this._validationMessages : []), ...optionsValidationMessages];
  }

  @Input()
  set validationMessages(value: ValidationMessageModel[]) {
    this._validationMessages = value;
  }

  private _selectedValue!: DATA_MODEL;

  get selectedValue(): DATA_MODEL {
    return this._selectedValue;
  }

  set selectedValue(value: DATA_MODEL) {
    this._selectedValue = value;
  }

  private _hasOneCheckLazyDelay!: number;

  get hasOneCheckLazyDelay(): number {
    return this._hasOneCheckLazyDelay;
  }

  @Input()
  set hasOneCheckLazyDelay(value: number) {
    this._hasOneCheckLazyDelay = coerceNumberProperty(value);
  }

  /**
   * ha be van kapcsolva akkor onInit-ben inditjuk amennyiben nincs meg kijelolt ertek
   * (amikor akkor lehet ha control-t ertekkel hozzak letre)
   */
  private _hasOneCheck = false;

  @Input()
  get hasOneCheck(): boolean {
    return this._hasOneCheck;
  }

  set hasOneCheck(value: boolean) {
    this._hasOneCheck = coerceBooleanProperty(value);
  }

  /**
   * when has selected element display mat prefix
   */
  private _hasPrefix = false;

  @Input()
  get hasPrefix(): boolean {
    return this._hasPrefix;
  }

  set hasPrefix(value: boolean) {
    this._hasPrefix = coerceBooleanProperty(value);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  private _optionsConfig!: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>;

  @Input()
  get optionsConfig(): LiveAutoCompleteOptionsConfigModel<DATA_MODEL> {
    return this._optionsConfig;
  }

  set optionsConfig(value: LiveAutoCompleteOptionsConfigModel<DATA_MODEL>) {
    if (value instanceof LiveAutoCompleteOptionsConfigModel) {
      this._optionsConfig = value;
    } else {
      throw new Error('Options config type missing LiveAutoCompleteOptionsConfigModel');
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (isNil(this._optionsConfig)) {
      throw new Error('Options config is required!');
    }
    if (isNil(this._optionsConfig.optionDisabledBindFn)) {
      throw new Error('Option disable bind is required!');
    }
    if (isNil(this._optionsConfig.optionDisplayFn)) {
      throw new Error('Option display bind is required!');
    }
    if (isNil(this._optionsConfig.displayFn)) {
      throw new Error('Display bind is required!');
    }
    if (isNil(this.name) || this.name.length === 0) {
      throw new Error('Name is required');
    }
    if (isNil(this.database) && isNil(this.databaseCallback)) {
      throw new Error('Database resource is required!');
    }
    if (isNil(this.entityService)) {
      this.entityService = this.injectedEntityService as any;
    }
    if (isNil(this.database) && isNil(this.entityService)) {
      throw new Error('Database or entity service is required!');
    }
    if (isNil(this.formControlRef)) {
      throw new Error('FormControlRef is required!');
    }

    // validatorok atvetele (TODO mi van ha dynamic valtoztatjak?)
    this.insideFormControl.setValidators(this.formControlRef.validator);
    this.insideFormControl.setAsyncValidators(this.formControlRef.asyncValidator);
    // set default value
    if (!isNil(this.formControlRef.value)) {
      this.overrideCurrentValue(this.formControlRef.value);
    }
    if (this.formControlRef.disabled) {
      this.insideFormControl.disable({ emitEvent: false });
    }
    this.formControlRef.statusChanges.subscribe((status) => {
      if (this.formControlRef.touched === true && this.insideFormControl.touched === false) {
        this.insideFormControl.markAsTouched();
      } else if (this.formControlRef.touched === false && this.insideFormControl.touched === true) {
        this.insideFormControl.markAsUntouched();
        this.insideFormControl.setErrors(null);
      }

      if (this.formControlRef.dirty === true && this.insideFormControl.dirty === false) {
        this.insideFormControl.markAsDirty();
      } else if (this.formControlRef.dirty === false && this.insideFormControl.dirty === true) {
        this.insideFormControl.reset(this.insideFormControl.value);
      }

      if (status === 'DISABLED' && this.insideFormControl.enabled) {
        this.insideFormControl.disable({ emitEvent: false });
      } else if (status.indexOf('VALID') > -1 && this.insideFormControl.disabled) {
        this.insideFormControl.enable({ emitEvent: false });
      }
    });

    this.twoWayBindControls();

    this.classList += ` ${this.name}`;
    if (this.panelAlwaysAbove === true) {
      this.classList += ' select-above';
    }

    this.hasOneCheckGet();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes['optionTplRef']) && !isNil(changes['optionTplRef'].currentValue)) {
      this.classList += ' has-option-tpl';
    }
    if (!isNil(changes['showLoader'])) {
      this.loading = changes['showLoader'].currentValue;
      this.cdr.markForCheck();
    }
  }

  /**
   * megnezi hogy csak 1 egyed letezik, ha igen akkor az egyedet kivalasztja
   * hasOneCheckResponse output-ban jelzunk az eredmenyrol(http valasz)
   * @param overrideThisHasOneCheck ha kivulrol vagy nem normalis esetben hivjuk meg, tehat altalaban nem a this hivja meg
   */
  hasOneCheckGet(overrideThisHasOneCheck = false): void {
    if (
      (this._hasOneCheck || overrideThisHasOneCheck) &&
      (isNil(this.insideFormControl.value) || !isObject(this.insideFormControl.value))
    ) {
      this.runHasOneCheck = true;
      this.showProgressSpinner$.next(true);

      this.loading = true;

      let obs = isFunction(this.databaseCallback)
        ? this.databaseCallback(
            { active: '', direction: this.sortDirection },
            {
              pageIndex: 0,
              pageSize: 2,
              length: 1,
            },
            ''
          )
        : this.database.getAll(
            // {
            //   active: this.database.entityDefaultOrder,
            //   direction: this.sortDirection,
            // },
            null as any,
            {
              pageIndex: 0,
              pageSize: 2,
              length: 1,
            },
            ''
          );
      if (isNumeric(this._hasOneCheckLazyDelay) && this._hasOneCheckLazyDelay > 0) {
        obs = obs.pipe(delay(this._hasOneCheckLazyDelay));
      }
      this.hasOneCheck$ = obs.pipe(untilDestroyed(this)).subscribe(
        (response: DATA_MODEL[]) => {
          this.loading = false;
          this.runHasOneCheck = false;
          if (Array.isArray(response) && response.length === 1) {
            this.formControlRef.setValue(response[0]);
            this.remoteConfig.hasNextDataPage = false;
          }
          this.showProgressSpinner$.next(false);
          timer(0).subscribe(() => this.hasOneCheckResponse.emit(response));
        },
        commonHttpErrorHandle(() => {
          this.loading = false;
          this.runHasOneCheck = false;
          this.showProgressSpinner$.next(false);
        })
      );
    }
  }

  onSelectionChange() {
    const selectedValue = (this.matSelect.selected as unknown as RawValue<DATA_MODEL>).rawValue;

    this._selectedValue = selectedValue;
    const value = (this.matSelect.selected as MatOption).value;
    this.disableNextFormControlRefValueChange = true;
    this.disableNextFormControlRefValueChangeValue = value;
    this.formControlRef.setValue(this.valueBind(selectedValue));
    this.selectionChange.emit(selectedValue);
    this.cdr.markForCheck();
  }

  openPanel() {
    this.matSelect.open();
  }

  valueBind(item: DATA_MODEL) {
    return isFunction(this.optionsConfig.optionValueBindFn) ? this.optionsConfig.optionValueBindFn(item) : item;
  }

  defaultCompare(o1: DATA_MODEL, o2: DATA_MODEL) {
    if (isObject(o1) || isObject(o2)) {
      let service: BaseCrudServiceInterface<DATA_MODEL>;
      if (!isNil(this.database)) {
        service = this.database;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        service = this.entityService!;
      }
      return service.getModelIdValue(o1) === service.getModelIdValue(o2);
    }
    return o1 === o2;
  }

  protected stopHasOneCheck(): void {
    if (this.runHasOneCheck && this.hasOneCheck$ !== undefined && !this.hasOneCheck$.closed) {
      this.loading = false;
      this.runHasOneCheck = false;
      this.loading$.next(false);
      this.hasOneCheck$.unsubscribe();
    }
  }

  private twoWayBindControls() {
    this.formControlRef.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((value) => {
          if (this.disableNextFormControlRefValueChange === true && deepEqual(this.disableNextFormControlRefValueChangeValue, value)) {
            this.disableNextFormControlRefValueChange = false;
            return false;
          }
          return true;
        })
      )
      .subscribe((value) => this.overrideCurrentValue(value));
  }

  private overrideCurrentValue(value: DATA_MODEL) {
    if (deepEqual(this.insideFormControl.value, value)) {
      /**
       * Ha ugyan az az ertek mint elozoleg akkor eldobjuk a valtozasokat,
       * pl: Ha disabled lesz a control, akkor sajnos a disable-rol is kapunk ertesites ami nem jo nekunk ...
       */
      return;
    }
    this.resetRemoteConfig();
    this._selectedValue = value;
    const isEmpty = isNil(value) || (isString(value) && value.length === 0);
    this.list$.next(isEmpty ? [] : [value]);
    this.insideFormControl.setValue(isEmpty ? value : this.valueBind(value));
    this.formControlRef.setValue(this.insideFormControl.value, { emitEvent: false });
    this.cdr.markForCheck();
  }

  onClickCustomIconButton() {
    this.clickCustomIconButton.emit();
  }

  onClickAutoCompleteClearButton(): void {
    this._selectedValue = undefined as any;
    this.insideFormControl.patchValue(undefined);
    this.resetRemoteConfig();
    this.clear.emit();
  }
}
