import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { fadeIn, fadeOut } from 'ng-animate';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { fadeInEnter } from '../../../../animations';
import { isFunction } from '../../../utils/type-guard/is-function';
import { menuHandleAfterClick } from '../menu-handle-after-click.function';
import { MenuConfigModel } from '../model/menu-config.model';
import { SubMenuConfigModel } from '../model/sub-menu-config.model';
import { ToggleSubMenuAction } from '../state/action/toggle-sub-menu.action';
import { MenuState } from '../state/menu.state';
import { isNil } from '../../../utils/type-guard/is-nil';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInEnter,
    trigger('openClose', [
      state('open', style({ display: 'block' })),
      state(
        'closed',
        style({
          opacity: 0,
        })
      ),
      transition('open => closed', useAnimation(fadeOut)),
      transition('closed => open', useAnimation(fadeIn)),
    ]),
  ],
})
export class SubMenuComponent implements OnInit, OnDestroy {
  @Output()
  selectMenu = new EventEmitter<SubMenuConfigModel>();
  customAreaCmpPortal!: ComponentPortal<any>;
  opened!: boolean;
  openedSubscription!: Subscription;
  visibleBottomDivider$!: Observable<boolean>;
  private customAreaCheckShowSubscription!: Subscription;

  constructor(private injector: Injector, private cdr: ChangeDetectorRef, private router: Router, private store: Store) {}

  private _chosenMenu!: {
    chosenMenu: MenuConfigModel;
    chosenSubMenu: SubMenuConfigModel;
  };

  get chosenMenu(): {
    chosenMenu: MenuConfigModel;
    chosenSubMenu: SubMenuConfigModel;
  } {
    return this._chosenMenu;
  }

  @Input()
  set chosenMenu(value: { chosenMenu: MenuConfigModel; chosenSubMenu: SubMenuConfigModel }) {
    if (
      this._chosenMenu === undefined ||
      value === undefined ||
      this._chosenMenu.chosenMenu.authority !== value.chosenMenu.authority ||
      this._chosenMenu.chosenMenu.title !== value.chosenMenu.title
    ) {
      if (this.customAreaCheckShowSubscription !== undefined) {
        this.customAreaCheckShowSubscription.unsubscribe();
      }
      if (!isNil(value) && isFunction(value.chosenMenu.customAreaCmp)) {
        if (isFunction(value.chosenMenu.customAreaCheckShow$)) {
          const cbArgs: any[] = [];
          if (Array.isArray(value.chosenMenu.customAreaCheckShow$Deps)) {
            value.chosenMenu.customAreaCheckShow$Deps.forEach((dep) => cbArgs.push(this.injector.get(dep)));
          }
          this.customAreaCheckShowSubscription = value.chosenMenu
            .customAreaCheckShow$(...cbArgs)
            .subscribe((show) => (show ? this.showCustomAreaComponent(value) : undefined));
        } else {
          this.showCustomAreaComponent(value);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete this.customAreaCmpPortal;
      }
    }
    this._chosenMenu = value;

    if (
      this._chosenMenu !== undefined &&
      this._chosenMenu.chosenMenu !== undefined &&
      this._chosenMenu.chosenMenu.bottom !== undefined &&
      this._chosenMenu.chosenMenu.bottom.length > 0
    ) {
      this.visibleBottomDivider$ = combineLatest(
        this._chosenMenu.chosenMenu.bottom.map((item) => (item._showContext$ ? item._showContext$ : new BehaviorSubject(true)))
      ).pipe(map((results) => results.findIndex((result) => result === true) > -1));
    } else {
      this.visibleBottomDivider$ = new BehaviorSubject(false);
    }
  }

  ngOnInit(): void {
    this.openedSubscription = this.store
      .select(MenuState.subMenuOpened)
      .pipe(untilDestroyed(this))
      .subscribe((subMenuOpened: boolean) => (this.opened = subMenuOpened));
  }

  onMenuToggle() {
    this.store.dispatch(new ToggleSubMenuAction());
  }

  changeSubmenu(subMenu: SubMenuConfigModel): void {
    this.selectMenu.emit(subMenu);
    menuHandleAfterClick(subMenu, this.injector);
  }

  ngOnDestroy(): void {
    if (this.customAreaCheckShowSubscription !== undefined) {
      this.customAreaCheckShowSubscription.unsubscribe();
    }
    if (this.openedSubscription !== undefined) {
      this.openedSubscription.unsubscribe();
    }
  }

  trackBySubMenuModelFn(index: any, item: SubMenuConfigModel) {
    return `${item.route}${item.title}${item.authority}`;
  }

  // @HostBinding('class.sub-menu')
  // readonly subMenuClass = true;
  // constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}
  //
  // navigate(path: string) {
  //   this.router.navigate([path]/*, { relativeTo: this.route }*/);
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.chosenMenu) {
  //     debugger;
  //   }
  // }

  onClickItem(item: SubMenuConfigModel) {
    if (!isNil(this.chosenMenu) && !isNil(this.chosenMenu.chosenSubMenu) /*&& item.title !== this.chosenMenu.chosenSubMenu.title*/) {
      this.router.navigate([item.route]);
      this.changeSubmenu(item);
    }
  }

  private showCustomAreaComponent(value: { chosenMenu: MenuConfigModel; chosenSubMenu: SubMenuConfigModel } | any) {
    const cbArgs: any[] = [];
    if (Array.isArray(value.chosenMenu.customAreaCmpDeps)) {
      value.chosenMenu.customAreaCmpDeps.forEach((dep: any) => cbArgs.push(this.injector.get(dep)));
    }
    const cmp = value.chosenMenu.customAreaCmp(...cbArgs);
    if (cmp !== null) {
      this.customAreaCmpPortal = new ComponentPortal<any>(cmp);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.customAreaCmpPortal;
    }
    this.cdr.markForCheck();
  }
}
