import { ValidationMessageModel } from '../modules/validating/model/validation-message.model';

export const validationMessagesConfig: ValidationMessageModel[] = [
  {
    errorKey: 'email',
    translateKey: 'VALIDATION.EMAIL',
  },
  {
    errorKey: 'min',
    translateKey: 'VALIDATION.MIN',
  },
  {
    errorKey: 'required',
    translateKey: 'VALIDATION.REQUIRED',
  },
  {
    errorKey: 'minlength',
    translateKey: 'VALIDATION.MIN_LENGTH',
  },
  {
    errorKey: 'maxlength',
    translateKey: 'VALIDATION.MAX_LENGTH',
  },
  {
    errorKey: 'noPhoneNumber',
    translateKey: 'VALIDATION.PHONE.NO_PHONE_NUMBER',
  },
  {
    errorKey: 'phoneNumberTooLong',
    translateKey: 'VALIDATION.PHONE.PHONE_NUMBER_TOO_LONG',
  },
  {
    errorKey: 'phoneNumberTooShort',
    translateKey: 'VALIDATION.PHONE.PHONE_NUMBER_TOO_SHORT',
  },
  {
    errorKey: 'phoneNumberInvalidCountryCode',
    translateKey: 'VALIDATION.PHONE.PHONE_NUMBER_INVALID_COUNTRY_CODE',
  },
];
