<div class="sidenav-container" *ngIf="isDesktop; else mobileTpl">
  <tg-menu></tg-menu>

  <div class="container-fluid" #scrollElement>
    <div class="content" [@.disabled]="disableAnimations">
      <router-outlet></router-outlet>
    </div>
  </div>

  <footer></footer>
</div>
<ng-template #mobileTpl>
  <tg-mobile-app-layout [disableAnimations]="disableAnimations" [appTitle$]="appTitle$" [menu$]="menu$"></tg-mobile-app-layout>
</ng-template>
