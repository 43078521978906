import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MobileAppLayoutComponent } from './mobile-app-layout/mobile-app-layout.component';

@NgModule({
  declarations: [MobileAppLayoutComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    TranslocoModule,
    MatIconModule,
    RouterModule,
    MatSidenavModule,
    MatRippleModule,
    MatButtonModule,
  ],
  exports: [MobileAppLayoutComponent],
})
export class MobileAppLayoutModule {}
