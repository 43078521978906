<h1 mat-dialog-title *ngIf="data?.title">{{ data.title }}</h1>
<div mat-dialog-content>
  <p *ngIf="data?.isHTML; else simpleTextTpl" [innerHTML]="data.text"></p>
  <ng-template #simpleTextTpl
    ><p>{{ data.text }}</p></ng-template
  >
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="1" *ngIf="data.hasCancel">
    {{ data.cancelButtonText }}
  </button>
  <button color="primary" mat-raised-button [mat-dialog-close]="0" cdkFocusInitial>
    {{ data.okButtonText }}
  </button>
</div>
