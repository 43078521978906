import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ShowTopLayoutLoaderAction } from './action/show-top-layout-loader.action';
import { HideTopLayoutLoaderAction } from './action/hide-top-layout-loader.action';
import { Injectable } from '@angular/core';

@State<{ visible: boolean }>({
  name: 'topLayoutLoader',
  defaults: {
    visible: false,
  },
})
@Injectable()
export class TopLayoutLoaderState {
  @Selector()
  static show(state: { visible: boolean }) {
    return state.visible;
  }

  @Action(ShowTopLayoutLoaderAction)
  showTopLayoutLoader({ patchState }: StateContext<{ visible: boolean }>) {
    return patchState({ visible: true });
  }

  @Action(HideTopLayoutLoaderAction)
  hideTopLayoutLoader({ patchState }: StateContext<{ visible: boolean }>) {
    return patchState({ visible: false });
  }
}
