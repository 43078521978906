import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UUID } from 'angular2-uuid';
import { environment } from '../../../environments/environment';
import { ParamsEncoder } from '../utils/http/params-encoder';
import { isString } from '../utils/type-guard/is-string';
import { UserAccessModel } from './state/model/user-access.model';
import { UserCompanyData } from './state/model/user-company-data';
import { UserMeModel } from './state/model/user-me.model';
import { UserStateModel } from './state/model/user-state.model';

export const oauthTokenUrl = `${environment.apiUrls.oauth}/token`;

@Injectable({ providedIn: 'root' })
export class UserService {
  readonly uuid: string;

  constructor(private http: HttpClient, private store: Store) {
    this.uuid = UUID.UUID();
  }

  login(username: string, password: string) {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);
    formData.append('scope', 'all');

    return this.http.post<UserAccessModel>(oauthTokenUrl, formData, {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Basic ${environment.clientSecret}`,
        Accept: 'application/json',
      },
    });
  }

  me() {
    return this.http.get<UserMeModel>(`${environment.apiUrls.userMe}`);
  }

  refreshToken(refresh_token: string) {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refresh_token);

    return this.http.post<UserAccessModel>(oauthTokenUrl, formData, {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Basic ${environment.clientSecret}`,
        Accept: 'application/json',
      },
    });
    // .pipe(tap(v => console.log(v)));
  }

  // getBussinessKey() {
  //   return this.http.get<
  //     {
  //       links: string[];
  //       id: string;
  //       definitionId: string;
  //       businessKey: string;
  //       caseInstanceId: string;
  //       ended: boolean;
  //       suspended: boolean;
  //       tenantId: string;
  //     }[]
  //   >(`${environment.apiUrls.processUrl}/rest/process-instance?processDefinitionKey=configureProduct`);
  // }

  getCompanyData() {
    return this.http.get<UserCompanyData>(
      `${environment.apiUrls.company}/api/company/${
        this.store.selectSnapshot((states) => (states['user'] as UserStateModel).me).tenants[0].refCompany
      }`
    );
  }

  // @Memoize(30 * 60 * 1000)
  getUsersProfilePictureImages(emails: Set<string>) {
    const params = new HttpParams({ encoder: new ParamsEncoder() })
      .set('reduced', 'true')
      .set('withProfilePicture', 'true')
      .set('idEqAny', Array.from(emails.values()).sort().join(','));
    return this.http.get<{ email: string; image: string; lastname: string; firstname: string }[]>(`${environment.apiUrls.userApi}/user`, {
      params,
    });
  }

  generateNewEmailSetParam(emails: string[]) {
    return new Set(emails.filter((v) => isString(v) && v.length > 0));
  }
}
