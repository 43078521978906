import { Directive, ElementRef, HostListener } from '@angular/core';

export interface HTMLElementWithHoverState extends HTMLElement {
  hover: boolean;
}

@Directive({
  selector: '[tgHoverState]',
  exportAs: 'tgHoverState',
})
export class HoverStateDirective {
  constructor(private el: ElementRef<HTMLElementWithHoverState>) {}

  private _hover = false;

  get hover(): boolean {
    return this._hover;
  }

  set hover(value: boolean) {
    this._hover = value;
    this.el.nativeElement.hover = value;
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.hover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hover = false;
  }
}
