import { LiveAutocompleDisplayFunction } from '../function-type/function/live-autocomple-display.function';
import { LiveAutocompleteOptionDisabledBindFunction } from '../function-type/function/live-autocomple-option-disabled-bind.function';
import { LiveAutocompleteOptionDisplayBindFunction } from '../function-type/function/live-autocomple-option-display-bind.function';
import { LiveAutocompleteOptionValueBindFunction } from '../function-type/function/live-autocomple-option-value-bind.function';
import { ValidationMessageModel } from '../../validating/model/validation-message.model';
import { isNil } from '../../utils/type-guard/is-nil';

const cache: { [key: string]: LiveAutoCompleteOptionsConfigModel<unknown> } = {};

export type liveAutoCompleteDiFactoryReturnType<E> = () => LiveAutoCompleteOptionsConfigModel<E>;

export function liveAutoCompleteCommonDiFactory(key: string, generateFn: () => LiveAutoCompleteOptionsConfigModel<unknown>) {
  return () => {
    if (cache[key] !== undefined) {
      return cache[key];
    }
    const cacheValue = generateFn();
    cache[key] = cacheValue;
    return cacheValue;
  };
}

export interface LiveAutoCompleteOptionsConfigModelInterface<MODEL> {
  /**
   * mikor legyen disable a talalati lista elem
   */
  readonly optionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<MODEL>;
  /**
   * hogy nezzen ki a talalati lista elem
   */
  readonly optionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<MODEL>;
  /**
   * milyen erteket taroljunk el a controlban a talalati lista elem kivalasztasakor
   */
  readonly optionValueBindFn: LiveAutocompleteOptionValueBindFunction<MODEL>;
  /**
   * kivalasztott elem hogy nezzen ki az inputban
   */
  readonly displayFn: LiveAutocompleDisplayFunction<MODEL>;
  readonly validatorMessages?: ValidationMessageModel[];
  readonly label?: string;
  readonly placeholder?: string;
}

export class LiveAutoCompleteOptionsConfigModel<MODEL> implements LiveAutoCompleteOptionsConfigModelInterface<MODEL> {
  readonly optionDisabledBindFn!: LiveAutocompleteOptionDisabledBindFunction<MODEL>;
  readonly optionDisplayFn!: LiveAutocompleteOptionDisplayBindFunction<MODEL>;
  readonly optionValueBindFn!: LiveAutocompleteOptionValueBindFunction<MODEL>;
  readonly displayFn!: LiveAutocompleDisplayFunction<MODEL>;
  readonly validatorMessages?: ValidationMessageModel[];
  readonly label?: string;
  readonly placeholder?: string;

  constructor(_this: LiveAutoCompleteOptionsConfigModelInterface<MODEL>) {
    if (isNil(_this) || Object.keys(_this).length === 0) {
      throw new Error('Not found options');
    }
    Object.assign(this, _this);
  }
}
