import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuDisplayComponent } from './menu-display/menu-display.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, TranslocoModule],
  declarations: [MenuDisplayComponent],
  exports: [MenuDisplayComponent],
})
export class MenuDisplayModule {}
