import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MobileAppLayoutModule } from '../mobile-app-layout/mobile-app-layout.module';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { MenuModule } from './menu/menu.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    MenuModule,
    TranslocoModule,
    MatRippleModule,
    MobileAppLayoutModule,
  ],
  declarations: [AppLayoutComponent],
})
export class AppLayoutModule {}
