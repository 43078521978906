import { registerLocaleData } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as countries from 'i18n-iso-countries';
import * as moment_ from 'moment';
import { asapScheduler, Subscription } from 'rxjs';
import { LOCALES, LOCALES_VALUE_TYPE } from '../../locales';
import { isNil } from '../utils/type-guard/is-nil';
import { SelectLanguageAction } from './action/select-language.action';
import { SetLanguageOptionsSuccessAction } from './action/set-language-options-success.action';
import { SetLanguageOptionsAction } from './action/set-language-options.action';
import { I18nStateModel } from './model/i18n-state.model';
import { TranslocoService } from '@ngneat/transloco';

const moment = moment_;

const angularLocalesSettings: any = {
  en: () =>
    import('@angular/common/locales/en').then((locale) =>
      import('@angular/common/locales/extra/en').then((extra) => ({
        locale: locale.default,
        extra: extra.default,
      }))
    ),
  hu: () =>
    import('@angular/common/locales/hu').then((locale) =>
      import('@angular/common/locales/extra/hu').then((extra) => ({
        locale: locale.default,
        extra: extra.default,
      }))
    ),
};

@State<I18nStateModel>({
  name: 'i18n',
  defaults: {
    selectedLang: 'HU',
    version: 1,
  },
})
@Injectable()
export class I18nState {
  private translateServiceSubscription!: Subscription;
  private registeredLocaleData: { [key: string]: boolean } = {};

  constructor(private translocoService: TranslocoService, private injector: Injector) {}

  @Selector()
  static selectedLang(state: I18nStateModel): LOCALES_VALUE_TYPE {
    return state.selectedLang;
  }

  @Action(SelectLanguageAction)
  selectLanguage({ patchState, dispatch }: StateContext<I18nStateModel>, { lang }: SelectLanguageAction) {
    if (isNil(lang) || LOCALES.indexOf(lang) === -1) {
      lang = 'HU';
    }
    patchState({ selectedLang: lang });
    asapScheduler.schedule(() => dispatch(new SetLanguageOptionsAction()));
  }

  @Action(SetLanguageOptionsAction)
  setLanguageOptions({ getState, dispatch }: StateContext<I18nStateModel>) {
    const selectedLang = getState().selectedLang.toLowerCase();
    this.translocoService.setActiveLang(selectedLang);
    if (this.translateServiceSubscription !== undefined && !this.translateServiceSubscription.closed) {
      this.translateServiceSubscription.unsubscribe();
    }

    if (isNil(this.registeredLocaleData[selectedLang])) {
      this.registeredLocaleData[selectedLang] = true;
      angularLocalesSettings[selectedLang]().then((localeSettings: any) =>
        registerLocaleData(localeSettings.locale, selectedLang, localeSettings.extra)
      );

      if (selectedLang === 'HU' || selectedLang ==='hu') {
        import('../../../../../../node_modules/i18n-iso-countries/langs/hu.json').then((json) => {
          countries.registerLocale(json);
        });
      } else {
        import('../../../../../../node_modules/i18n-iso-countries/langs/en.json').then((json) => countries.registerLocale(json));
      }
    }

    moment.locale(selectedLang);
    this.injector.get(DateAdapter).setLocale(selectedLang);
    dispatch(new SetLanguageOptionsSuccessAction());
  }
}
