import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { menuConfig } from '../../../config/menu.config';
import { matSnackbarErrorConfig } from '../../common/mat-snackbar-error.config';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class HasAccessRouteGuard implements CanActivateChild {
  constructor(
    private menuService: MenuService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state.url);
  }

  check(url: string, autoRedirect = true) {
    return this.menuService.findSelectedMenuItemByCurrentUrl(url).pipe(
      take(1),
      switchMap((menu: any) => {
        if (menu.navigateUrl !== undefined) {
          return this.menuService.findSelectedMenuItemByCurrentUrl(url, menuConfig).pipe(
            map<any, boolean | UrlTree>((_menu) => {
              let route: string;
              if (_menu.selectedMenus['chosenSubMenu'] !== undefined) {
                route = _menu.selectedMenus['chosenSubMenu'].title;
              } else {
                route = _menu.selectedMenus['chosenMenu'].title;
              }
              this.matSnackBar.open(
                this.translocoService.translate('COMMON_ERROR.NOT_ACCESS_ROUTE', {
                  route,
                }),
                this.translocoService.translate('COMMON.SNACKBAR.CLOSE'),
                matSnackbarErrorConfig
              );

              if (autoRedirect) {
                return this.router.parseUrl(menu.navigateUrl);
              }
              return false;
            })
          );
        }

        if (menu.selectedMenus.chosenSubMenu !== undefined && url.startsWith(menu.selectedMenus.chosenSubMenu.route)) {
          return of<boolean>(true);
        }
        if (menu.selectedMenus.chosenSubMenu !== undefined) {
          if (menu.selectedMenus.chosenSubMenu.route !== url) {
            return of(this.router.parseUrl(menu.selectedMenus.chosenSubMenu.route));
          }
        } else if (menu.selectedMenus.chosenMenu !== undefined && menu.selectedMenus.chosenMenu.route !== url) {
          return of(this.router.parseUrl(menu.selectedMenus.chosenMenu.route));
        }
        return of<boolean>(true);
      })
    );
  }
}
