import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddUserIdToIconPipe } from './add-user-id-to-icon.pipe';

@NgModule({
  declarations: [AddUserIdToIconPipe],
  imports: [CommonModule],
  exports: [AddUserIdToIconPipe],
})
export class AddUseIdToIconPipeModule {}
