export class MessageAddAction {
  static readonly type = '[Message] Add';
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(
    readonly message: string,
    readonly linkText: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    readonly linkClick: Function
  ) {}
}
