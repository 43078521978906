import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { asapScheduler, Observable, throwError } from 'rxjs';
// import { produce } from 'immer';
import { catchError, tap } from 'rxjs/operators';
import { UserState } from '../../user/state/user.state';
import { ProcessService } from '../process.service';
import { ProcessLoadProcessInstanceErrorAction } from './action/process-load-process-instance-error.action';
import { ProcessLoadProcessInstanceSuccessAction } from './action/process-load-process-instance-success.action';
import { ProcessLoadProcessInstanceAction } from './action/process-load-process-instance.action';
import { ProcessLoadTaskByProcessDefinitionKeyErrorAction } from './action/process-load-task-by-process-definition-key-error.action';
import { ProcessLoadTaskByProcessDefinitionKeySuccessAction } from './action/process-load-task-by-process-definition-key-success.action';
import { ProcessLoadTaskByProcessDefinitionKeyAction } from './action/process-load-task-by-process-definition-key.action';
import { ProcessLoadTaskErrorAction } from './action/process-load-task-error.action';
import { ProcessLoadTaskSuccessAction } from './action/process-load-task-success.action';
import { ProcessLoadTaskAction } from './action/process-load-task.action';
import { ProcessRefreshUserTaskErrorAction } from './action/process-refresh-user-task-error.action';
import { ProcessRefreshUserTaskSuccessAction } from './action/process-refresh-user-task-success.action';
import { ProcessRefreshUserTaskAction } from './action/process-refresh-user-task.action';
import { ProcessSetInstanceidAction } from './action/process-set-instanceid.action';
import { ProcessStateModel } from './model/process-state.model';
import { TaskModel } from './model/task.model';
import { isNil } from '../../utils/type-guard/is-nil';
import { Injectable } from '@angular/core';

@State<ProcessStateModel>({
  name: 'process',
  defaults: {
    task: null,
    processInstance: null,
    processInstanceId: null,
    version: 1,
  } as any,
})
@Injectable()
export class ProcessState {
  @Selector()
  static processInstanceBusinessKey(state: ProcessStateModel) {
    return !isNil(state.processInstance) ? state.processInstance.businessKey : null;
  }

  @Selector()
  static taskId(state: ProcessStateModel): string {
    return !isNil(state.task) ? state.task.id : '';
  }

  constructor(private readonly processService: ProcessService, private readonly store: Store) {}

  @Action([ProcessSetInstanceidAction, ProcessRefreshUserTaskSuccessAction])
  processSetInstanceid(
    { patchState, dispatch }: StateContext<ProcessStateModel>,
    action: ProcessSetInstanceidAction | ProcessRefreshUserTaskSuccessAction
  ) {
    if (action instanceof ProcessSetInstanceidAction) {
      return patchState({ processInstanceId: action.processInstanceId });
    } else {
      return patchState({
        processInstanceId: !isNil(action.task) ? action.task.processInstanceId : '',
      });
    }
  }

  @Action(ProcessLoadTaskAction)
  processLoadTask({ dispatch, getState }: StateContext<ProcessStateModel>, action: ProcessLoadTaskAction) {
    let method: Observable<TaskModel>;
    if (action.loadWithProcessInstanceId === true) {
      method = this.processService.taskDataByProcessInstanceId(getState().processInstanceId);
    } else {
      method = this.processService.taskData(this.store.selectSnapshot(UserState.tenantId));
    }
    return method.pipe(
      tap((task) => asapScheduler.schedule(() => dispatch(new ProcessLoadTaskSuccessAction(task, action.loadProcessInstance)))),
      catchError((error) => {
        asapScheduler.schedule(() => dispatch(new ProcessLoadTaskErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(ProcessLoadTaskSuccessAction)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  processLoadTaskSuccess({ patchState, dispatch }: StateContext<ProcessStateModel>, action: ProcessLoadTaskSuccessAction) {
    patchState({
      task: action.task,
      processInstanceId: action.task.processInstanceId,
    });
    if (action.loadProcessInstance) {
      return dispatch(new ProcessLoadProcessInstanceAction());
    }
  }

  // TODO ez kell ? ? ?? ? ? ?
  @Action(ProcessLoadProcessInstanceAction)
  processLoadProcessInstance({ getState, dispatch }: StateContext<ProcessStateModel>) {
    const state = getState();
    return this.processService.refreshProcessInstance(state.task.processInstanceId).pipe(
      tap((processInstance) => asapScheduler.schedule(() => dispatch(new ProcessLoadProcessInstanceSuccessAction(processInstance)))),
      catchError((error) => {
        asapScheduler.schedule(() => dispatch(new ProcessLoadProcessInstanceErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(ProcessLoadProcessInstanceSuccessAction)
  processLoadProcessInstanceSuccess({ patchState }: StateContext<ProcessStateModel>, action: ProcessLoadProcessInstanceSuccessAction) {
    return patchState({
      processInstance: action.processInstance,
      processInstanceId: action.processInstance.id,
    });
  }

  @Action(ProcessRefreshUserTaskAction)
  processRefreshUserTask({ dispatch }: StateContext<ProcessStateModel>) {
    return this.processService.taskData(this.store.selectSnapshot(UserState.tenantId)).pipe(
      tap((task) => asapScheduler.schedule(() => dispatch(new ProcessRefreshUserTaskSuccessAction(task)))),
      catchError((error) => {
        asapScheduler.schedule(() => dispatch(new ProcessRefreshUserTaskErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(ProcessRefreshUserTaskSuccessAction)
  processRefreshUserTaskSuccess({ patchState, dispatch }: StateContext<ProcessStateModel>, action: ProcessRefreshUserTaskSuccessAction) {
    if (!isNil(action.task)) {
      return patchState({
        task: action.task,
        processInstanceId: action.task !== null ? action.task.processInstanceId : '',
      });
    } else {
      return patchState({
        task: undefined,
        processInstanceId: undefined,
      });
    }
    // if (action.task !== null) {
    //   return dispatch(new ProcessLoadProcessInstanceAction());
    // }
  }

  // @Action(ProcessLoadTaskErrorAction)
  // processLoadTaskError() {
  //   // TODO hibakezeles
  // }

  @Action(ProcessLoadTaskByProcessDefinitionKeyAction)
  processLoadTaskByProcessDefinitionKey(
    { dispatch, patchState }: StateContext<ProcessStateModel>,
    action: ProcessLoadTaskByProcessDefinitionKeyAction
  ) {
    return this.processService.taskDataByProcessDefinitionKey(action.processDefinitionKey).pipe(
      tap((task) => {
        if (!isNil(task)) {
          return patchState({
            task: task,
            processInstanceId: task !== null ? task.processInstanceId : null,
          } as ProcessStateModel);
        } else {
          return patchState({
            task: undefined,
            processInstanceId: undefined,
          });
        }
      }),
      tap((task) => asapScheduler.schedule(() => dispatch(new ProcessLoadTaskByProcessDefinitionKeySuccessAction(task)))),
      catchError((error) => {
        asapScheduler.schedule(() => dispatch(new ProcessLoadTaskByProcessDefinitionKeyErrorAction(error)));
        return throwError(error);
      })
    );
  }
}
